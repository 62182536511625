/*!

 =========================================================
 * Paper Dashboard Pro Angular - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-angular
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

import { datadogRum } from '@datadog/browser-rum';

if (environment.production) {
	enableProdMode();
}

datadogRum.init({
    applicationId: environment.datadogApplicationId,
    clientToken: environment.datadogClientToken,
    site: 'us3.datadoghq.com',
    service: environment.datadogService,
    env: environment.name,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 90,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    version: environment.version,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [environment.baseUrl],
    beforeSend: (event, context) => {

        var errorStringsToIgnore = ["AG Grid",
            "ag-grid",
            "evaluation only version",
            "License Key Not Found",
            "Failed to start the transport 'WebSockets'",
            "Failed to start the transport 'ServerSentEvents'",
            "SignalR",
            "WebSocket",
            "Failed to start the transport 'LongPolling'",
            "No Connection with that ID: Status code '404'",
            "make sure you installed the scripts"];

        var regex = new RegExp(`(${errorStringsToIgnore.join("|")})`, "i");
        if (event.type === 'error' && (Boolean(event.error.message.match(regex)) || event.error.message.includes("********"))) {
            return false;
        }

        return true;
    }
});

platformBrowserDynamic().bootstrapModule(AppModule);
