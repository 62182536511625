import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class NewCustomerAccountFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(
			`${environment.apiUrl}utility-billing/clients/$$clientId$$/customer-account-form`,
			httpClient,
			utilityService
		);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	fetch(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}land-management/clients/${clientId}/reports`, request)
			.pipe();
	}

	getCustomerDeposits(clientId: string, customerId: any): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${environment.apiUrl}utility-billing/clients/${clientId}/customer-account-form/deposits/${customerId}`)
			.pipe();
	}

	updateDepositBillingPeriod(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(
				`${environment.apiUrl}utility-billing/clients/${clientId}/customer-account-form/updatedepositbillingperiod/`,
				request
			)
			.pipe();
	}

}
