import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ChangeOrderCreate } from '../../models/form/ChangeOrderCreate';
import { ChangeOrderView } from '../../models/form/ChangeOrderView';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { PoRolloverView } from 'app/models/form/PoRolloverView';
import { DynamicFormUtilityServices } from '../dynamic-form-utility.service';

@Injectable({
	providedIn: 'root',
})
export class PoRolloverFormService {
	private readonly urlPath = 'po-rollover-form';
	constructor(
		private httpClient: HttpClient,
		private utilityService: UtilityService,
		private dynamicFormUtilityServices: DynamicFormUtilityServices
	) {}

	createForm(clientId: string, request: any, prefix: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`, this.setupRequest(request, prefix))
			.pipe();
	}

	private setupRequest(request: any, prefix: string) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));

		//Append Addtional fields Files
		this.dynamicFormUtilityServices.appendDocuments(formData, prefix, request);

		return formData;
	}

	updateForm(clientId: string, id: string, request: any, prefix: string): Observable<any> {
		return this.httpClient
			.put<any>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`,
				this.setupRequest(request, prefix)
			)
			.pipe();
	}

	getForm(id: string, clientId: string): Observable<PoRolloverView> {
		return this.httpClient
			.get<PoRolloverView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`)
			.pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/docs/${docId}`
		);
	}
	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<PoRolloverView> {
		return this.httpClient
			.post<PoRolloverView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<PoRolloverView> {
		return this.httpClient
			.post<PoRolloverView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/docs`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
