import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnouncementForView, CreateAnnouncementView } from 'app/models/announcements/CreateAnnouncementView';
import { LoadingService } from 'app/services/loading.service';
import { NotificationService } from 'app/services/notification.service';
import { UserService } from 'app/services/user.service';
import { UtilityService } from 'app/services/utility.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as _ from 'lodash';
import { AnnouncementService } from 'app/services/Announcement/announcement.service';
import { ClientsService } from 'app/services/clients.service';
import { User } from 'app/models/User';
import { Client } from 'app/models/Client';
import * as moment from 'moment';
import { datePickerValidator } from 'app/components/client/datePicker.validator';
import Swal from 'sweetalert2';
import { CommentsComponent } from 'app/components/client/workflow/forms/comments/comments.component';
import { DragDropFileComponent } from 'app/components/client/workflow/forms/file-upload/drag-drop-file/drag-drop-file.component';

declare var $: any;

@Component({
	selector: 'app-announcements-create',
	templateUrl: './announcements-create.component.html',
	styleUrls: ['./announcements-create.component.css'],
})
export class AnnouncementsCreateComponent implements OnInit {
	@ViewChild('dragDropFileComponent') dragDropFileComponent: DragDropFileComponent;
	@ViewChild('commentComponent') commentComponent: CommentsComponent;
	form: FormGroup;
	submitted = false;
	clientId: string;
	allUsers: User[];
	users: User[];
	clients: Client[];
	item: CreateAnnouncementView;
	annoucementFor: AnnouncementForView[];
	readonly id: string;
	public Editor = ClassicEditor;
	message: any;

	isRejected: boolean;
	userId: any;
	canEdit: boolean | undefined;
	currentStep: string;
	isEditing: boolean;
	canApprove: boolean = false;
	constructor(
		private formBuilder: FormBuilder,
		private userService: UserService,
		private clientsService: ClientsService,
		private router: Router,
		private utilityService: UtilityService,
		private announcementService: AnnouncementService,
		private notificationService: NotificationService,
		private loadingService: LoadingService,
		private activatedRoute: ActivatedRoute,
		private titleService: Title
	) {
		this.annoucementFor = [];
		this.titleService.setTitle(`Create Announcement`);
		this.clientId = this.userService.currentUserValue.user.clientId;
		this.id = this.utilityService.testId(this.activatedRoute.snapshot.paramMap.get('id'));
		this.userId = this.userService.currentUserValue.user.id;
		this.form = this.formBuilder.group({
			id: ['00000000-0000-0000-0000-000000000000'],
			title: [null, [Validators.required]],
			scheduledDate: ['', [Validators.required, datePickerValidator()]],
			message: ['', [Validators.required]],
			announcementFors: [null],
			requestedBy: [this.userService.currentUserValue.user.email],
			clientId: [this.clientId],
			userId: [''],
			attachments: new FormArray([]),
		});

		this.Editor.defaultConfig = {
			toolbar: {
				items: [
					'heading',
					'|',
					'bold',
					'italic',
					'link',
					'|',
					'bulletedList',
					'numberedList',
					'|',
					'Blockquote',
					'insertTable',
					'|',
					'indent',
					'outdent',
					'|',
					'undo',
					'redo',
				],
			},
			table: {
				contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
			},
			language: 'en',
		};
	}

	ngOnInit(): void {
		this.addFile();
		this.loadingService.OnLoading.emit(true);
		const allusersRequest = this.userService.getUsers().toPromise();
		const clientRequest = this.clientsService.getClientList().toPromise();
		const getUsersForClientRequest = this.userService.getUsersForClient(this.clientId).toPromise();

		const promiseArray = this.id
			? [
					allusersRequest,
					clientRequest,
					getUsersForClientRequest,
					this.announcementService.get(this.clientId, this.id).toPromise(),
			  ]
			: [allusersRequest, clientRequest, getUsersForClientRequest];

		this.isEditing = !this.id;

		Promise.all(promiseArray).then(([allusersResponse, clientResponse, userResponse, announcementResponse]) => {
			this.allUsers = allusersResponse;
			this.clients = clientResponse;
			this.users = userResponse;

			if (this.id) {
				this.item = announcementResponse;
				this.setForm();
				this.loadingService.OnLoading.emit(false);
			} else {
				this.loadingService.OnLoading.emit(false);
			}
		});
	}

	loadUserDropdowns(clientId) {
		this.loadingService.OnLoading.emit(true);
		this.userService
			.getUsersForClient(clientId)
			.toPromise()
			.then((userResponse) => {
				this.loadingService.OnLoading.emit(false);
				this.users = userResponse;
			});
	}

	get f() {
		return this.form.controls;
	}

	setForm() {
		this.form.reset();
		this.canApprove = this.userService.canApproveForm(this.item, this.clientId);
		this.canEdit = this.userService.canEditForm(this.item);
		this.isRejected = this.item.declinedReason != null && this.item.declinedReason.length > 0;
		let currentStep = this.item?.workflowSteps?.find((t) => t.isCurrent);
		if (currentStep != null) {
			this.currentStep = currentStep.name;
		}
		this.f.id.setValue(this.item.id);
		this.f.title.setValue(this.item.title);
		this.f.scheduledDate.setValue(moment(this.item.scheduledDate).format('MM/DD/YYYY'));
		this.f.message.setValue(this.item.message);
		this.f.clientId.setValue(this.item.clientId);
		this.form.disable();
		this.utilityService.scrollToTop();
	}

	ngAfterViewInit(): void {
		let self = this;
		setTimeout(() => {
			$('#scheduledDate').datepicker(
				{
					onSelect: function (e, ele) {
						let date = moment(new Date(e));
						if (date.isValid()) {
							self.f.scheduledDate.setValue(moment(new Date(e)).format('MM/DD/YYYY'));
						}
					},
				},
				500
			);
		});
	}

	onSubmit() {
		this.submitted = true;
		if (this.form.invalid) {
			return;
		}

		if (this.annoucementFor?.length <= 0) {
			this.notificationService.showError('please add atleast one annoucement for', 'Error!');
			return;
		}

		let request = _.cloneDeep(this.form.value);

		request.announcementFors = this.annoucementFor;

		this.loadingService.OnLoading.emit(true);

		let requestCall = null;
		if (this.id) {
			requestCall = this.announcementService.updateFormWithDocuments(this.clientId, this.id, request);
		} else {
			requestCall = this.announcementService.createFormWithDocuments(this.clientId, request);
		}

		requestCall.subscribe(
			(data) => {
				this.loadingService.OnLoading.emit(false);
				if (this.id) {
					this.isEditing = false;
					this.submitted = false;
					this.setForm();
				} else {
					this.router.navigateByUrl(`/announcements/edit/${data.id}`).then();
				}
			},
			(err) => {
				this.loadingService.OnLoading.emit(false);
				this.notificationService.showError(err, 'Error!');
			}
		);
	}

	removeUserFromAnnoucement(userId) {
		var index = this.annoucementFor.findIndex((x) => x.userId == userId);
		this.annoucementFor.splice(index, 1);
	}

	onUserChange() {
		let userId = this.f.userId.value;

		if (userId == '') return;

		if (userId == 'all') {
			if (this.f.clientId.value == 'all') {
				this.clients.forEach((c) => {
					this.allUsers
						.filter((x) => x.client?.split(',').includes(c.name) || false)
						.forEach((u) => {
							this.addUserToList(u.id, c.id);
						});
				});
			} else {
				this.users.forEach((u) => {
					if (!this.annoucementFor.find((x) => x.userId == userId && x.clientId == this.f.clientId.value)) {
						this.addUserToList(u.id, this.f.clientId.value);
					}
				});
			}

			if (this.clientId != '16731dca-45e0-4cc5-a9f5-2f0a759db4af') {
				this.annoucementFor.sort(function (a, b) {
					return a.userName?.toLowerCase() < b.userName?.toLowerCase() ? -1 : 1;
				});
			}
		} else {
			if (this.f.clientId.value == 'all') {
				let tempUser = this.users.find((x) => x.id == userId);
				if (this.annoucementFor.find((x) => x.userId == userId && x.clientId == tempUser?.clientId)) {
					this.notificationService.showWarning('already in list', 'warning!');
					return;
				}
			} else {
				if (this.annoucementFor.find((x) => x.userId == userId && x.clientId == this.f.clientId.value)) {
					this.notificationService.showWarning('already in list', 'warning!');
					return;
				}
			}

			this.addUserToList(userId, this.f.clientId.value);
		}
	}

	onClientChange() {
		if (this.f.clientId.value == '') return;

		if (this.f.clientId.value == 'all') {
			this.users = [];
			this.clients.forEach((c) => {
				this.allUsers
					.filter((x) => x.client?.split(',').includes(c.name) || false)
					.forEach((u) => {
						this.addUserToList(u.id, c.id);

						u.clientId = c.id;
						u.client = c.name;
						this.users.push(u);
					});
			});

			this.annoucementFor.sort(function (a, b) {
				return a.clientName?.toLowerCase() < b.clientName?.toLowerCase() ? -1 : 1;
			});
		} else {
			this.loadUserDropdowns(this.f.clientId.value);
		}
	}

	addUserToList(userId, clientId) {
		if (this.annoucementFor.find((x) => x.userId == userId && x.clientId == clientId)) return;

		this.annoucementFor.push(this.getAnnouncementForView(userId, clientId));
	}

	onEditorChange({ editor }) {
		this.f.message.setValue(editor.getData());
	}

	getClientName(clientId) {
		let client = this.clients.find((x) => x.id == clientId);
		return client?.name;
	}

	getUserName(userId) {
		let user = this.allUsers.find((x) => x.id == userId);
		return user.firstName + ' ' + user.lastName;
	}

	clearList() {
		this.annoucementFor = [];
	}

	getAnnouncementForView(userId, clientId): AnnouncementForView {
		let user = this.allUsers.find((x) => x.id == userId);

		var obj = new AnnouncementForView();
		obj.clientId = clientId;
		obj.userId = user.id;
		obj.clientName = this.getClientName(clientId);
		obj.userName = this.getUserName(user.id);

		return obj;
	}

	onReady(event): void {
		setTimeout(() => {
			if (this.item?.id) {
				event.data.set(this.item.message);
				this.item.announcementFors.forEach((announcement) => {
					this.annoucementFor.push(this.getAnnouncementForView(announcement.userId, announcement.clientId));
				});
				this.setForm();
			}
		}, 5000);
	}
	approveWorkflow(callback) {
		this.loadingService.OnLoading.emit(true);
		this.announcementService.approveForm(this.clientId, this.item.id, this.userId).subscribe(
			(data) => {
				this.setForm();
				callback();
				location.reload();
			},
			(err) => {
				this.loadingService.OnLoading.emit(false);
				this.notificationService.showError(err, 'Error!');
			}
		);
	}

	get filesArray(): FormArray {
		return this.form.controls.attachments as FormArray;
	}
	rejectWorkflow(workflowData: any) {
		this.loadingService.OnLoading.emit(true);
		this.announcementService
			.rejectForm(this.clientId, this.item.id, {
				reason: workflowData.reason,
				workflowId: workflowData.workflowId,
			})
			.subscribe(
				(data) => {
					this.loadingService.OnLoading.emit(false);
					this.setForm();
					workflowData.callback();
				},
				(err) => {
					this.loadingService.OnLoading.emit(false);
					this.notificationService.showError(err, 'Error!');
				}
			);
	}
	canSendForReview() {
		return this.utilityService.canSendForReview(this.item, this.userId) || this.canApprove;
	}

	sendBack() {
		this.loadingService.OnLoading.emit(true);
		this.announcementService.sendBack(this.clientId, this.id).subscribe((data) => {
			this.loadingService.OnLoading.emit(false);
			this.setForm();
			location.reload();
		});
	}

	editForm() {
		this.isEditing = true;
		if (this.isEditing) {
			this.form.enable();
		}
	}

	cancelEdit() {
		this.submitted = false;
		this.isEditing = false;
		this.loadingService.OnLoading.emit(true);
		this.announcementService.get(this.clientId, this.id).subscribe(
			(data) => {
				this.setForm();
				this.loadingService.OnLoading.emit(false);
			},
			(error) => {
				this.loadingService.OnLoading.emit(false);
			}
		);

		this.form.disable();
	}
	deleteDocument(doc: any, index: number) {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to remove this document. You won't be able to undo again?",
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'I am sure!',
			cancelButtonText: 'No, cancel it!',
			confirmButtonClass: 'btn btn-success',
			cancelButtonClass: 'btn btn-danger',
			buttonsStyling: false,
		}).then((result) => {
			if (!result.value) {
				return;
			}
			this.loadingService.OnLoading.emit(true);

			this.announcementService.deleteDocument(this.clientId, this.item.id, doc.id).subscribe((results) => {
				this.loadingService.OnLoading.emit(false);
				this.item.documents.splice(index, 1);
			});
		});
	}

	private createFileGroup(): FormGroup {
		let currentFileCount = (this.filesArray?.controls?.length || 0) + 1;
		return new FormGroup({
			label: new FormControl(`File ${currentFileCount}`),
			file: new FormControl(),
			order: new FormControl(currentFileCount),
		});
	}

	onFileChanged(file: any, i: number) {
		let currentVal = this.filesArray.controls[i].value;
		currentVal.file = file;
		this.filesArray.controls[i].patchValue(currentVal);
	}
	addFile() {
		this.filesArray.push(this.createFileGroup());
	}
	removeFile() {
		this.filesArray.removeAt(this.filesArray.controls.length - 1);
	}
	canSubmitForApproval() {
		return this.item.createdBy === this.userId || this.userService.isAdminToEditForm(this.clientId);
	}

	submitForApproval() {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you want to submit this form for approval?',
			type: 'warning',
			showCancelButton: true,
			confirmButtonText: 'I am sure!',
			cancelButtonText: 'No, cancel it!',
			confirmButtonClass: 'btn btn-success',
			cancelButtonClass: 'btn btn-danger',
			buttonsStyling: false,
		}).then((result) => {
			if (result.value) {
				this.loadingService.OnLoading.emit(true);
				this.announcementService.submitForApproval(this.clientId, this.id, this.userId).subscribe(
					(data) => {
						this.loadingService.OnLoading.emit(false);
						location.reload();
					},
					(error) => {
						this.loadingService.OnLoading.emit(false);
						this.notificationService.showError(error, 'Error');
					}
				);
			} else {
				Swal.fire({
					title: 'Cancelled',
					text: 'This form has not been submitted.',
					type: 'error',
					confirmButtonClass: 'btn btn-info',
					buttonsStyling: false,
				}).then();
			}
		});
	}
}
