import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncementsCreateComponent } from './announcements-create/announcements-create.component';
import { AnnouncementsListComponent } from './announcements-list/announcements-list.component';
import { AnnouncementsViewComponent } from './announcements-view/announcements-view.component';

const routes: Routes = [
	{
		path: 'view',
		component: AnnouncementsViewComponent,
	},
	{
		path: 'list',
		component: AnnouncementsListComponent,
	},
	{
		path: 'create',
		component: AnnouncementsCreateComponent,
	},
	{
		path: 'edit/:id',
		component: AnnouncementsCreateComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AnnouncementsRoutingModule {}
