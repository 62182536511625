import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ChangeOrderCreate } from '../../models/form/ChangeOrderCreate';
import { ChangeOrderView } from '../../models/form/ChangeOrderView';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { DynamicFormUtilityServices } from '../dynamic-form-utility.service';

@Injectable({
	providedIn: 'root',
})
export class ChangeOrderFormService {
	private readonly urlPath = 'change-order';
	constructor(
		private httpClient: HttpClient,
		private utilityService: UtilityService,
		private dynamicFormUtilityServices: DynamicFormUtilityServices
	) {}

	createForm(clientId: string, form: ChangeOrderCreate, prefix: string): Observable<ChangeOrderView> {
		const formData: FormData = new FormData();
		for (let i = 0; i < form.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, form.attachments[i].file);
			form.attachments[i].name = name;
		}

		//Append Addtional fields Files
		this.dynamicFormUtilityServices.appendDocuments(formData, prefix, form);

		formData.append('model', JSON.stringify(form));
		return this.httpClient
			.post<ChangeOrderView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`, formData)
			.pipe();
	}

	updateForm(clientId: string, formId: string, request: ChangeOrderCreate, prefix: string): Observable<any> {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}

		//Append Addtional fields Files
		this.dynamicFormUtilityServices.appendDocuments(formData, prefix, request);

		formData.append('model', JSON.stringify(request));

		return this.httpClient
			.put<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`, formData)
			.pipe();
	}

	getForm(id: string, clientId: string): Observable<ChangeOrderView> {
		return this.httpClient
			.get<ChangeOrderView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/docs/${docId}`
		);
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<ChangeOrderView> {
		return this.httpClient
			.post<ChangeOrderView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<ChangeOrderView> {
		return this.httpClient
			.post<ChangeOrderView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
