<footer class="footer no-print">
	<div class="container-fluid">
		<nav class="pull-left">
			<ul>
				<li>
					<a href="https://www.aclarian.com/" target="_blank"> Aclarian.com </a>
				</li>
			</ul>
		</nav>
		<div class="copyright pull-right">
			&copy; {{ test | date : 'yyyy' }}, made by <a href="https://www.aclarian.com/" target="_blank"> Aclarian LLC </a>
		</div>
	</div>
</footer>
