import { HttpHeaders, HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { UsageFormView } from 'app/models/inventory/usageFormView';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from '../BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class UsageFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}inventory/clients/$$clientId$$/usage-form`, httpClient, utilityService);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	getFormsByItemId(clientId: string, itemId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/getFormsByItemId/${itemId}`).pipe();
	}
}
