import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ItemView } from 'app/models/inventory/itemView';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class ItemService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}inventory/clients/$$clientId$$/item`, httpClient, utilityService);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	AddItemsToInventory(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/add-items`, request).pipe();
	}
	GetItemDetail(clientId: string, id: any): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/${id}/item-detail`).pipe();
	}
}
