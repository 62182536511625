import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormSearch, FormStatus } from 'app/models/dashboard/FormStatus';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FundRevenueBudgetView } from 'app/models/form/FundRevenueBudgetView';
import { map } from 'rxjs/operators';
import { UtilityService } from 'app/services/utility.service';
import { environment } from 'environments/environment';
import { BaseFormService } from 'app/services/BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class FundRevenueBudgetServices extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/revenue-budget`, httpClient, utilityService);
	}

	getBudgetFor(clientId: string, fundId: any, fiscalYear: number): Observable<FundRevenueBudgetView> {
		return this.httpClient
			.get<FundRevenueBudgetView>(`${this.getUrl(clientId)}/funds/${fundId}?fiscalYear=${fiscalYear}`)
			.pipe(
				map((data) => {
					if (data.id == '00000000-0000-0000-0000-000000000000') {
						data.id = '00000000-0000-0000-0000-000000000000';
					}
					return data;
				})
			);
	}

	getAvailableBudgets(clientId: string, fiscalYear: number): Observable<FundRevenueBudgetView[]> {
		return this.httpClient.get<FundRevenueBudgetView[]>(`${this.getUrl(clientId)}/available?fiscalYear=${fiscalYear}`);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/${id}/review`, {}).pipe();
	}
	revertRevenueBudgetForm(clientId: string, id: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.getUrl(clientId)}/${id}/RevertRevenueBudgetForm`, {}).pipe();
	}
}
