import { Injectable } from '@angular/core';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MassPayRateChangeService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}hris/clients/$$clientId$$/forms/mass-pay-rate-change-form`, httpClient, utilityService);
	}

	getFormsForClient(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/GetPreApprovalByClient`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
