import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { ApInvoiceDuplicateSearchRequest } from 'app/models/form/ApInvoiceDuplicateSearchRequest';
import { CustomerRefundFormView } from 'app/models/form/CustomerRefundFormView';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class CustomerRefundFormService {
	private readonly urlPath = 'customer-refund-form';
	private duplicateRequest = null;
	private showDuplicateInvoicePopup = new BehaviorSubject<boolean>(false);

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) { }

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`, this.setupRequest(request))
			.pipe();
	}

	setupRequest(request: any): FormData {
		const formData: FormData = new FormData();

		for (let i = 0; i < request.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}
		formData.append('model', JSON.stringify(request));
		return formData;
	}

	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`, this.setupRequest(request))
			.pipe();
	}

	getForm(clientId: string, formId: string): Observable<CustomerRefundFormView> {
		return this.httpClient
			.get<CustomerRefundFormView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: any): Observable<CustomerRefundFormView> {
		return this.httpClient
			.post<CustomerRefundFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`,
				{
					userId,
				}
			)
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<CustomerRefundFormView> {
		return this.httpClient
			.post<CustomerRefundFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${this.urlPath
			}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/docs`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/docs/${docId}`
		);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<CustomerRefundFormView> {
		return this.httpClient
			.post<CustomerRefundFormView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	searchForDuplicate(clientId: string, request: ApInvoiceDuplicateSearchRequest): Observable<CustomerRefundFormView[]> {
		if (JSON.stringify(this.duplicateRequest).toLowerCase() != JSON.stringify(request).toLowerCase()) {
			this.duplicateRequest = request;
			return this.httpClient
				.post<CustomerRefundFormView[]>(
					`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/search-for-duplicate`,
					request
				)
				.pipe();
		} else {
			return of([]);
		}
	}

	showDuplicateInvoicePopupChange$ = this.showDuplicateInvoicePopup.asObservable();
	setShowDuplicateInvoicePopup(value: boolean) {
		this.showDuplicateInvoicePopup.next(value);
	}
}
