import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { UtilityService } from '../utility.service';
import { ApInvoiceDuplicateSearchRequest } from 'app/models/form/ApInvoiceDuplicateSearchRequest';
import { BaseFormService } from 'app/services/BaseFormService';
import { environment } from 'environments/environment';
import { CheckRequestView } from 'app/models/form/CheckRequestView';

@Injectable({
	providedIn: 'root',
})
export class MultipleVendorPaymentServices extends BaseFormService {
	private duplicateRequest = null;
	private response: Observable<CheckRequestView[]>;
	private showDuplicateInvoicePopup = new BehaviorSubject<boolean>(false);

	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/multiple-vendor-payment`, httpClient, utilityService);
	}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(	`${this.getUrl(clientId)}`, this.setupRequest(request))
			.pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.getUrl(clientId)}/${id}`, this.setupRequest(request))
			.pipe();
	}

    setupRequest(request: any) {
		const formData: FormData = new FormData();
		formData.append('model', JSON.stringify(request));
		return formData;
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<CheckRequestView> {
		return this.httpClient
			.post<CheckRequestView>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	convertToApinvoice(clientId: string, id: any, poNumber: string, userId): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/convert-form/${poNumber}`, {
				userId,
			})
			.pipe();
	}
	convertToQuickPay(clientId: string, id: any, userId): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/convert-quick-form/`, {
				userId,
			})
			.pipe();
	}
	searchForDuplicate(clientId: string, request: ApInvoiceDuplicateSearchRequest): Observable<CheckRequestView[]> {
		if (JSON.stringify(this.duplicateRequest).toLowerCase() != JSON.stringify(request).toLowerCase()) {
			this.duplicateRequest = request;
			this.response = this.httpClient
				.post<CheckRequestView[]>(`${this.getUrl(clientId)}/search-for-duplicate`, request)
				.pipe();
		}
		return this.response;
	}

	getRecurringForms(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/recurring-forms`).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.getUrl(clientId)}/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	showDuplicateInvoicePopupChange$ = this.showDuplicateInvoicePopup.asObservable();
	setShowDuplicateInvoicePopup(value: boolean) {
		this.showDuplicateInvoicePopup.next(value);
	}

	deleteStatement(clientId: string, formId: string, id: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${this.getUrl(clientId)}/${formId}/statements/${id}`)
			.pipe();
	}
}
