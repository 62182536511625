import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class MeterTurnOffFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}utility-billing/clients/$$clientId$$/meter-turn-off-form`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	GetCustomerAccountsByFilter(clientId: string, filter: any): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.apiUrl}utility-billing/clients/${clientId}/customer-account-form/filter/${filter}`
		);
	}
	
	getMeterByCustomerAccount(clientId: string, customerAccountId: any) {
		return this.httpClient.get<any>(
			`${this.getUrl(clientId)}/meters-by-customer-account/${customerAccountId}`
		);
	}

	getMetersByPropertyId(clientId: string, propertyId: any) {
		return this.httpClient.get<any>(
			`${this.getUrl(clientId)}/meters-by-property/${propertyId}`
		);
	}
	getPastDueMeters(clientId: string) {
		return this.httpClient.get<any>(
			`${this.getUrl(clientId)}/past-due-meters`
		);
	}

	generateMeterTurnOffForms(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/generate-meter-turn-offs`, request)
			.pipe();
	}
}
