import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { NotificationService } from 'app/services/notification.service';

@Component({
	selector: 'app-drag-drop-file',
	templateUrl: './drag-drop-file.component.html',
	styleUrls: ['./drag-drop-file.component.css'],
})
export class DragDropFileComponent implements OnInit {
	@ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef<HTMLInputElement>;

	constructor(private notificationService: NotificationService) {}

	ngOnInit(): void {}

	files: any[] = [];

	/**
	 * on file drop handler
	 */
	onFileDropped($event) {
		this.prepareFilesList($event);
	}

	/**
	 * handle file from browsing
	 */
	fileBrowseHandler(files) {
		this.prepareFilesList(files);
	}

	/**
	 * Delete file from files list
	 * @param index (File index)
	 */
	deleteFile(index: number) {
		if (index > -1) {
			if (this.fileDropEl.nativeElement.files.length > 0) {
				this.removeFileFromFileList(this.fileDropEl.nativeElement.files, index);
			}
			this.files.splice(index, 1);
		}
	}

	removeFileFromFileList(files, index) {
		const dt = new DataTransfer();

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (index !== i) dt.items.add(file); // here you exclude the file. thus removing it.
		}

		this.fileDropEl.nativeElement.files = dt.files; // Assign the updates list
	}

	/**
	 * Simulate the upload process
	 */
	uploadFilesSimulator(index: number, upload: Observable<HttpEvent<any>>): Promise<boolean> {
		this.files[index].progress = 0;
		return new Promise<boolean>((resolve) => {
			let file = this.files[index];
			upload.subscribe(
				(event) => {
					if (event.type === HttpEventType.Response) {
						this.files[index].progress = 100;
						resolve(true);
					} else if (event.type === HttpEventType.UploadProgress) {
						if (this.files[index].progress <= 90) {
							this.files[index].progress += 5;
						}
					}
				},
				(err) => {
					this.files[index].progress = 0;
					this.notificationService.showError('Could not upload the file:' + file.name, 'Error');
					resolve(false);
				}
			);
		});
	}

	/**
	 * Convert Files list to normal array list
	 * @param files (Files List)
	 */
	prepareFilesList(files: Array<any>) {
		for (const item of files) {
			item.progress = 0;
			this.files.push(item);
		}
	}

	/**
	 * format bytes
	 * @param bytes (File size in bytes)
	 * @param decimals (Decimals point)
	 */
	formatBytes(bytes, decimals) {
		if (bytes === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
}
