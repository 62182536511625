import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { CCApprovalView } from '../../models/form/CCApprovalView';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class CCApprovalService {
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: CCApprovalView): Observable<CCApprovalView> {
		const formData: FormData = new FormData();
		for (let i = 0; i < form.statements.length; i++) {
			let randomText = this.getRandomText(15);
			form.statements[i].fileHash = randomText;
			formData.append(randomText, form.statements[i].attachment);
		}
		if (form.monthlyStatement) {
			formData.append('monthlyStatement', form.monthlyStatement);
		}

		formData.append('model', JSON.stringify(form));
		return this.httpClient
			.post<CCApprovalView>(`${environment.apiUrl}forms/clients/${clientId}/cc-approval`, formData)
			.pipe();
	}

	getRandomText(length) {
		const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.match(/./g);
		let text = '';
		for (let i = 0; i < length; i++) {
			text += charset[Math.floor(this.cryptoRandomGenerator() * charset.length)];
		}
		return text;
	}
	cryptoRandomGenerator() {
		const typedArray = new Uint8Array(1);
		const randomValue = crypto.getRandomValues(typedArray)[0];
		const randomFloat = randomValue / Math.pow(2, 8);
		return randomFloat;
	}
	getForm(id: string, clientId: string): Observable<CCApprovalView> {
		return this.httpClient
			.get<CCApprovalView>(`${environment.apiUrl}forms/clients/${clientId}/cc-approval/${id}`)
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<CCApprovalView> {
		return this.httpClient
			.post<CCApprovalView>(
				`${environment.apiUrl}forms/clients/${clientId}/cc-approval/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<CCApprovalView> {
		return this.httpClient
			.post<CCApprovalView>(`${environment.apiUrl}forms/clients/${clientId}/cc-approval/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			environment.apiUrl
		}forms/clients/${clientId}/cc-approval/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	editForm(clientId: string, id: any, request: any) {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.statements.length; i++) {
			let randomText = this.getRandomText(15);
			request.statements[i].fileHash = randomText;
			formData.append(randomText, request.statements[i].attachment);
		}

		if (request.monthlyStatement) {
			formData.append('monthlyStatement', request.monthlyStatement);
		}

		formData.append('model', JSON.stringify(request));
		return this.httpClient
			.post<CCApprovalView>(`${environment.apiUrl}forms/clients/${clientId}/cc-approval/${id}`, formData)
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<CCApprovalView> {
		return this.httpClient
			.post<CCApprovalView>(`${environment.apiUrl}forms/clients/${clientId}/cc-approval/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	deleteStatement(clientId: string, formId: string, id: string): Observable<CCApprovalView> {
		return this.httpClient
			.delete<CCApprovalView>(`${environment.apiUrl}forms/clients/${clientId}/cc-approval/${formId}/statements/${id}`)
			.pipe();
	}

	deleteApprover(clientId: string, formId: any, userId: string): Observable<CCApprovalView> {
		return this.httpClient
			.delete<CCApprovalView>(
				`${environment.apiUrl}forms/clients/${clientId}/cc-approval/${formId}/approvers/${userId}`
			)
			.pipe();
	}
	sendBack(clientId: string, formId: string): Observable<CCApprovalView> {
		return this.httpClient
			.post<CCApprovalView>(`${environment.apiUrl}forms/clients/${clientId}/cc-approval/${formId}/review`, {})
			.pipe();
	}
}
