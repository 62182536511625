import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { CashReceiptFormView } from 'app/models/miscellaneous-billing/CashReceiptFormView';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from '../BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class FeeAdjustmentFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}utility-billing/clients/$$clientId$$/fee-adjustment-form`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getCustomerInvoices(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/${id}/invoices`).pipe();
	}

	getAllfee(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/allfee`).pipe();
	}

	getFeeAdjustmentByCustomerAccount(clientId: string, accountId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/feeAdjustment/${accountId}`).pipe();
	}
}
