<div [hidden]="!allApprovers || allApprovers.length == 0">
	<h5 [hidden]="hideHeading">Approvers</h5>
	<div class="card">
		<div class="card-body table-responsive">
			<table class="table table-bordered">
				<thead>
					<th>Name</th>
					<th>Approved By</th>
					<th>Workflow Step</th>
					<th *ngIf="!hideCurrentApproveColumn">Is Current Approver?</th>
					<th>Substitute For</th>
					<th>Approved Date</th>
				</thead>
				<tbody>
					<tr *ngFor="let approver of allApprovers">
						<td>
							<span *ngIf="approver.groupName"
								><strong>Group: </strong> <u>{{ approver.groupName }}</u> <br />
							</span>
							{{ approver.name }}
						</td>
						<td>
							<span *ngIf="approver.hasApproved">
								{{ approver.approvedBy }}
								<strong class="text-secondary" *ngIf="approver.userId != approver.approvedById && approver.approvedById"
									>(Skipped)</strong
								>
							</span>
						</td>
						<td>{{ approver.workflowStep }}</td>
						<td *ngIf="!hideCurrentApproveColumn">{{ isNextApprover(approver) ? 'Yes' : 'No' }}</td>
						<td>{{ approver.oldUserName }}</td>
						<td>{{ approver.approvedDate | date : 'short' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
