import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

declare let ga: any;

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {
	private subscription: Subscription;

	constructor(private router: Router) {}

	public subscribe() {
		if (!this.subscription) {
			// subscribe to any router navigation and once it ends, write out the google script notices
			this.subscription = this.router.events.subscribe((e) => {
				if (e instanceof NavigationEnd) {
					// this will find & use the ga function pulled via the google scripts
					try {
						ga('set', 'page', e.urlAfterRedirects);
						ga('send', 'pageview');
						//this.monitoringService.logPageView(e.urlAfterRedirects, e.url);
					} catch {
						//console.error('tracking not found - make sure you installed the scripts');
					}
				}
			});
		}
	}

	public unsubscribe() {
		if (this.subscription) {
			// --- clear our observable subscription
			this.subscription.unsubscribe();
		}
	}
}
