import { WorkflowForm } from '../WorkflowForm';
import { AttachmentView } from '../form/AttachmentView';

export interface CreateAnnouncementView extends WorkflowForm {
	id: string;
	title: string;
	message: string;
	scheduledDate: Date;
	announcementFors: AnnouncementForView[];
	documents: AttachmentView[];
}

export class AnnouncementForView {
	id: string;
	userId: string;
	userName: string;
	clientId: string;
	clientName: string;
	isRead: boolean;
}
