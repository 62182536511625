import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { HRSupportView } from '../../models/form/HRSupportView';
import { FormCommentView } from '../../models/form/FormCommentView';
import { HRSupportCreator } from '../../models/form/HRSupportCreator';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SupportRequestService {
	private readonly baseUrl: string;
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {
		this.baseUrl = `${environment.apiUrl}forms/hr-support`;
	}

	createForm(form: HRSupportCreator): Observable<any> {
		return this.httpClient.post<any>(`${this.baseUrl}`, form).pipe();
	}

	getFormsFor(userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.baseUrl}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	getForm(id: string): Observable<HRSupportView> {
		return this.httpClient.get<HRSupportView>(`${this.baseUrl}/${id}`).pipe();
	}

	rejectForm(formId: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient.post<any>(`${this.baseUrl}/${formId}/decline`, rejectionReason).pipe();
	}

	approveForm(formId: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.baseUrl}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	submitDeliverables(formId: string, request: HRSupportView): Observable<any> {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.documents.length; i++) {
			const name = `deliverable-${i + 1}`;
			formData.append(name, request.documents[i].file);
			request.documents[i].file = null;
			request.documents[i].name = name;
		}

		formData.append('model', JSON.stringify(request));

		return this.httpClient.post<any>(`${this.baseUrl}/${formId}/deliverables`, formData).pipe();
	}

	removeDeliverable(formId: string, documentId: string): Observable<any> {
		return this.httpClient.delete<any>(`${this.baseUrl}/${formId}/deliverables/${documentId}`).pipe();
	}

	sendComment(formId: string, item: FormCommentView): Observable<any> {
		return this.httpClient.post<any>(`${this.baseUrl}/${formId}/comments`, item).pipe();
	}

	sendBack(formId: string): Observable<any> {
		return this.httpClient.post<any>(`${this.baseUrl}/${formId}/review`, {}).pipe();
	}
}
