<div class="wrapper">
	<div class="sidebar no-print sidebar-pointer-events" data-color="brown" data-active-color="danger">
		<sidebar-cmp></sidebar-cmp>
	</div>
	<div class="main-panel">
		<navbar-cmp></navbar-cmp>
		<router-outlet></router-outlet>
		<div *ngIf="!isMap()">
			<footer-cmp></footer-cmp>
		</div>
	</div>
</div>
