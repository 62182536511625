import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UtilityService } from '../utility.service';
import { BaseFormService } from '../BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class SpecialPayService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}hris/clients/$$clientId$$/special-pay-form`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getSpecialPayReport(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/special-pay-report`, request)
			.pipe();
	}

	getCompletedForms(clientId: string, payrollType: number): Observable<any> {
		return this.httpClient
			.get<any>(`${this.getUrl(clientId)}/completed-forms/${payrollType}`)
			.pipe();
	}

	downloadBenefitReportExcelFile(clientId: string, request: any): Observable<Blob> {
		return this.httpClient.post<Blob>(`${this.getUrl(clientId)}/export`,request , {
			responseType: 'blob' as any,
		});
	}
}
