import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {SidebarModule} from './sidebar/sidebar.module';
import {FooterModule} from './shared/footer/footer.module';
import {NavbarModule} from './shared/navbar/navbar.module';
import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {AppRoutes} from './app.routing';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './security/jwt.interceptor';
import {ErrorInterceptor} from './security/error.interceptor';
import {NgxLoadingModule} from 'ngx-loading';
import {ErrorHandlerService} from './services/error-handler.service';
import {ToastrModule} from 'ngx-toastr';
import {AgGridModule} from 'ag-grid-angular';
import {AnnouncementsModule} from './client/announcements/announcements.module';
import {EffectiveDatePopupComponent} from './components/client/workflow/forms/effective-date-popup/effective-date-popup.component';
import {EditDeleteBtnRenderV2Component} from './components/client/ag-grid/edit-delete-btn-render-v2.component';
import {VendorAutopayValidationComponent} from './components/client/workflow/vendor-autopay-validation/vendor-autopay-validation.component';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true,
            enableTracing: false,
        }),
        NgbModule,
        HttpClientModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        NgxLoadingModule.forRoot({
            backdropBackgroundColour: 'rgba(0,0,0,0.3)',
        }),
        ToastrModule.forRoot(),
        AgGridModule.withComponents([]),
        AnnouncementsModule,
    ],
    declarations: [AppComponent, 
		AdminLayoutComponent,
		AuthLayoutComponent, 
		EffectiveDatePopupComponent, 
		EditDeleteBtnRenderV2Component],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: ErrorHandler, useClass: ErrorHandlerService},
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {
}
