import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateAnnouncementView } from 'app/models/announcements/CreateAnnouncementView';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class AnnouncementService extends BaseFormService {
	private readonly baseUrl: string;
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}announcement/clients/$$clientId$$`, httpClient, utilityService);
		this.baseUrl = `${environment.apiUrl}announcement/clients`;
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	getAllAnnouncement(clientId: string): Observable<any> {
		return this.httpClient.get<CreateAnnouncementView[]>(`${this.baseUrl}/${clientId}`).pipe();
	}
	getAnnouncementByUserId(clientId: string, userId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/GetAnnouncementByUserId/${userId}`).pipe();
	}

	announcementMarkAsRead(clientId: string, announcementForId: string, userId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.baseUrl}/${clientId}/AnnouncementMarkAsRead/${announcementForId}/${userId}`)
			.pipe();
	}

	announcementMarkAsArchive(clientId: string, announcementForId: string, userId: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.baseUrl}/${clientId}/AnnouncementMarkAsArchive/${announcementForId}/${userId}`)
			.pipe();
	}
}
