import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class LeaveBalanceAdjustmentFormService {
	private readonly urlPath = `${environment.apiUrl}hris/clients/`;

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) { }
	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/leave-balance`, this.setupRequest(request)).pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		return formData;
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/leave-balance/${id}`, this.setupRequest(request))
			.pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/leave-balance/${id}`).pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/leave-balance/${id}/decline`, rejectionReason).pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/leave-balance/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.urlPath}${clientId}/leave-balance/users/${userId}?${this.utilityService.objectToQueryString(
			search
		)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/leave-balance/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/leave-balance/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/leave-balance/${formId}/docs/${docId}`);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/leave-balance/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/leave-balance/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getLeaveBalanceReport(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/leave-balance/leave-balance-report`, request)
			.pipe();
	}
}
