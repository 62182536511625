import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-btn-cell-renderer',
	template: ` <a href="/#/announcements/edit/{{ params.value }}" class="btn btn-simple btn-warning btn-icon edit">
			<i class="fa fa-edit"></i>
		</a>
		<button (click)="onClick($event)" class="btn btn-simple btn-danger btn-icon delete">
			<i class="fa fa-times"></i>
		</button>`,
})
export class BtnCellRendererComponent implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	btnClickedHandler(event) {
		this.params.clicked(this.params.value);
	}

	refresh(params: ICellRendererParams): boolean {
		return true;
	}

	onClick($event) {
		if (this.params.onclick instanceof Function) {
			const params = {
				id: this.params.node.data.id,
			};
			this.params.onclick(params);
		}
	}
}
