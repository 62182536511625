import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { HttpClient } from '@angular/common/http';
import { BaseFormService } from '../BaseFormService';

@Injectable({
  providedIn: 'root'
})
export class VendorCreditMemoService extends BaseFormService {
  constructor(httpClient: HttpClient, utilityService: UtilityService) {
    super(`${environment.apiUrl}forms/clients/$$clientId$$/vendor-credit-memo-form`, httpClient, utilityService);
  }
  submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
    return this.httpClient
      .post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
        userId,
      })
      .pipe();
  }
  changeStatus(clientId: string, id: string) {
    return this.httpClient
      .get<any>(`${this.getUrl(clientId)}/${id}/change-status`)
      .pipe();
  }
    getAllMemos(clientId: string, type: any, vendorId: any, paymentFormId: any) {
        return this.httpClient
            .get<any>(`${this.getUrl(clientId)}/${type}/${vendorId}/search?paymentFormId=${paymentFormId}`)
      .pipe();
    }

    getMemoById(clientId: string, id: any) {
        return this.httpClient
            .get<any>(`${this.getUrl(clientId)}/${id}`)
            .pipe();
    }
}