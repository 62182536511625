import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Client } from 'app/models/Client';
import { User } from 'app/models/User';
import { AnnouncementService } from 'app/services/Announcement/announcement.service';
import { ClientsService } from 'app/services/clients.service';
import { LoadingService } from 'app/services/loading.service';
import { NotificationService } from 'app/services/notification.service';
import { UserService } from 'app/services/user.service';
import { UtilityService } from 'app/services/utility.service';
import { BtnCellRendererComponent } from '../common/btn-cell-renderer/btn-cell-renderer.component';

declare var $: any;

@Component({
	selector: 'app-announcements-list',
	templateUrl: './announcements-list.component.html',
	styleUrls: ['./announcements-list.component.css'],
})
export class AnnouncementsListComponent implements OnInit {
	items = [];
	private table: any;
	clientId: any;
	clients: Client[];
	allUsers: User[];
	genrateAll = false;
	public gridApi;
	public gridColumnApi;
	public columnDefs;
	public autoGroupColumnDef;
	public defaultColDef;
	public rowSelection;
	public rowGroupPanelShow;
	public pivotPanelShow;
	public gridOptions;
	public rowData: [];
	public paginationPageSize = 20;
	public keepDetailRowsCount;
	public sideBar;
	public frameworkComponents: any;
	constructor(
		private loadingService: LoadingService,
		private notifyService: NotificationService,
		private userService: UserService,
		private clientsService: ClientsService,
		private announcementService: AnnouncementService,
		public utilityService: UtilityService,
		private titleService: Title
	) {
		this.clientId = this.userService.currentUserValue.user.clientId;
		this.titleService.setTitle(`Announcement Listing`);
		this.GridSetUp();
	}

	ngOnInit(): void {
		this.loadingService.OnLoading.emit(true);
		const allusersRequest = this.userService.getUsers().toPromise();
		const clientRequest = this.clientsService.getClientList().toPromise();

		Promise.all([allusersRequest, clientRequest]).then(([allusersResponse, clientResponse]) => {
			this.allUsers = allusersResponse;
			this.clients = clientResponse;
			this.onGridReady();
			this.loadingService.OnLoading.emit(false);
		});
	}

	GridSetUp() {
		this.columnDefs = [
			{
				field: 'id',
				headerName: 'View',
				cellRenderer: (params) => {
					return (
						'<a href="#announcements/edit/' +
						params.value +
						'" class="btn btn-sm btn-info" style="color:black;"><i class="fa fa-eye"></i></a>'
					);
				},
				minWidth: 90,
			},
			{ field: 'title', headerName: 'Title', filter: 'agTextColumnFilter' },
			{
				field: 'scheduledDate',
				headerName: 'Scheduled date',
				filter: 'agTextColumnFilter',
				valueFormatter: (params) => {
					return this.utilityService.convertToDate(params.value);
				},
			},
			{
				field: 'message',
				headerName: 'Message',
				filter: 'agTextColumnFilter',
				cellRenderer: (params) => {
					return `<div>${params.value}</div>`;
				},
			},
		];

		if (this.clientId == '16731dca-45e0-4cc5-a9f5-2f0a759db4af') {
			this.columnDefs.push({
				field: null,
				headerName: 'Clients',
				filter: 'agTextColumnFilter',
				cellRenderer: (params) => {
					let response = `<div> <ul>`;
					let names = [];
					params.data.announcementFors.forEach((announcementFor) => {
						let name = this.getClientName(announcementFor.clientId);
						if (!names.includes(name)) {
							response = response.concat(`<li>${name}</li>`);
							names.push(name);
						}
					});

					return response + `</ul></div>`;
				},
			});
		}

		this.columnDefs.push(
			{
				field: 'createdBy',
				headerName: 'Created By',
				filter: 'agTextColumnFilter',
				cellRenderer: (params) => {
					return this.getUserName(params.value);
				},
			},
			{
				field: 'workflowStep',
				headerName: 'Status',
				cellRenderer: (params) => {
					return params.value != null ? params.value : 'Draft';
				},
			}
		);

		this.defaultColDef = {
			enableRowGroup: true,
			floatingFilter: true,
			enableValue: true,
			sortable: true,
			resizable: true,
			filter: true,
			flex: 1,
			minWidth: 100,
			wrapText: true,
			autoHeight: true,
		};
		this.gridOptions = {
			context: {
				thisComponent: this,
			},
		};
		this.sideBar = {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
				},
			],
			position: 'left',
			defaultToolPanel: '',
		};
	}

	getContextMenuItems(params) {
		var result = ['copy', 'separator', 'export'];
		return result;
	}

	onGridReady() {
		this.loadingService.OnLoading.emit(true);
		this.announcementService.getAllAnnouncement(this.clientId).subscribe(
			(data) => {
				this.rowData = data;
				this.loadingService.OnLoading.emit(false);
			},
			(error) => {
				this.loadingService.OnLoading.emit(false);
				this.notifyService.showError('Unable to load list.', 'Error');
			}
		);
	}

	getClientName(clientId) {
		let client = this.clients.find((x) => x.id == clientId);
		return client.name;
	}

	getUserName(userId) {
		let user = this.allUsers.find((x) => x.id == userId);
		return user.firstName + ' ' + user.lastName;
	}

	onPageSizeChanged() {
		this.gridApi.paginationSetPageSize(Number(this.paginationPageSize));
	}

	clearFilters() {
		this.gridApi.setFilterModel(null);
	}

	exportToExcel() {
		this.gridApi.exportDataAsExcel();
	}
}
