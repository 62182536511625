import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { ElectronicAchReportView } from 'app/models/form/ElectronicAchReportView';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class ElectronicAchReportService {
	private readonly urlPath = 'electronic-ach-report';

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: ElectronicAchReportView): Observable<ElectronicAchReportView> {
		const formData: FormData = new FormData();
		formData.append('model', JSON.stringify(form));
		return this.httpClient
			.post<ElectronicAchReportView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`, formData)
			.pipe();
	}

	getForm(id: string, clientId: string): Observable<ElectronicAchReportView> {
		return this.httpClient
			.get<ElectronicAchReportView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`)
			.pipe();
	}
	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<ElectronicAchReportView> {
		return this.httpClient
			.post<ElectronicAchReportView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<ElectronicAchReportView> {
		return this.httpClient
			.post<ElectronicAchReportView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`,
				{
					userId,
				}
			)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<ElectronicAchReportView> {
		return this.httpClient
			.post<ElectronicAchReportView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	findForms(clientId: string,request: any): Observable<ElectronicAchReportView[]> {
		return this.httpClient
			.post<ElectronicAchReportView[]>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/find-forms`,request).pipe();
	}

	exportData(clientId: string, id:any): Observable<any> {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/export-data/${id}`, {
				responseType: 'blob',
			})
			.pipe();
	}
	exportToExcel(clientId: string, id: any): Observable<any> {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/export-to-excel/${id}`, {
				responseType: 'blob',
			})
			.pipe();
		}
}
