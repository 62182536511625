import { Injectable } from '@angular/core';
import { BaseFormService } from 'app/services/BaseFormService';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from 'app/services/utility.service';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ReclassificationFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}capital-assets/clients/$$clientId$$/reclassification`, httpClient, utilityService);
	}
}
