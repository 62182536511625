import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkflowGroupDto } from 'app/models/WorkflowGroupDto';
import { environment } from 'environments/environment';
import { UtilityService } from './utility.service';
import { BaseFormService } from 'app/services/BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class WorkflowGroupService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}workflow-management/clients/$$clientId$$/workflow-group`, httpClient, utilityService);
	}

	getList(clientId: string): Observable<WorkflowGroupDto[]> {
		return this.httpClient.get<WorkflowGroupDto[]>(`${this.getUrl(clientId)}/all`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<WorkflowGroupDto> {
		return this.httpClient
			.post<WorkflowGroupDto>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
