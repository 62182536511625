import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { UtilityService } from './utility.service';

@Injectable({
	providedIn: 'root',
})
export class RecurringTemplateService {
	private readonly baseUrl: string;
	private readonly urlPath = 'recurring-template';
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {
		this.baseUrl = `${environment.apiUrl}forms/clients`;
	}
	getAllTemplates(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.baseUrl}/${clientId}/recurring-template`).pipe();
	}
	deleteTemplate(clientId: string, templateId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${this.baseUrl}/${clientId}/recurring-template/delete-template/${templateId}`)
			.pipe();
	}
	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}
}
