import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from '../BaseFormService';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class OtherReimbursableExpensesService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/other-reimbursable-expenses`, httpClient, utilityService);
	}

	createForm(clientId: string, request: any): Observable<any> {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.reimbursableExpenses.length; i++) {
			let randomText = this.utilityService.getRandomText(15);
			request.reimbursableExpenses[i].fileHash = randomText;
			for (let att = 0; att < request.reimbursableExpenses[i].attachments.length; att++) {
				formData.append(randomText, request.reimbursableExpenses[i].attachments[att].file);
				request.reimbursableExpenses[i].attachments[att] = null;
			}
		}
		formData.append('model', JSON.stringify(request));
		return this.httpClient.post<any>(`${this.getUrl(clientId)}`, formData).pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.reimbursableExpenses.length; i++) {
			let randomText = this.utilityService.getRandomText(15);
			request.reimbursableExpenses[i].fileHash = randomText;
			for (let att = 0; att < request.reimbursableExpenses[i].attachments.length; att++) {
				formData.append(randomText, request.reimbursableExpenses[i].attachments[att].file);
				request.reimbursableExpenses[i].attachments[att] = null;
			}
		}
		formData.append('model', JSON.stringify(request));
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/${id}`, formData).pipe();
	}

	getFormsForClient(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/GetPreApprovalByClient`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
