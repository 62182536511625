<div class="main-content">
	<div class="col-md-12 mr-auto ml-auto">
		<div class="wizard-container">
			<div class="row no-print">
				<div class="col-12">
					<div class="row">
						<div class="col-6">
							<button class="btn btn-default" *ngIf="canEdit && id && !isEditing" (click)="editForm()">Edit</button>
							<button class="btn btn-default" *ngIf="isEditing && id" (click)="cancelEdit()">Cancel Edit</button>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header">
							<h4 class="card-title">Announcements {{ isRejected ? '(Rejected)' : '' }}</h4>
						</div>
						<div class="card-body">
							<div class="row" *ngIf="item">
								<div class="col-md-12">
									<client-stepper-workflow [workflowSteps]="item?.workflowSteps"> </client-stepper-workflow>
								</div>
							</div>
							<form (ngSubmit)="onSubmit()" novalidate [formGroup]="form" class="form-horizontal">
								<div class="row">
									<div class="col-6">
										<label class="required">Title</label>
										<div class="form-group">
											<input
												type="text"
												[ngClass]="{
													'has-error': !f.title.valid && submitted
												}"
												class="form-control"
												formControlName="title"
											/>
										</div>
									</div>
									<div class="col-6">
										<label class="required">Scheduled Date</label>
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="scheduledDate"
												name="scheduledDate"
												formControlName="scheduledDate"
												autocomplete="off"
												[ngClass]="{
													'has-error': !f.scheduledDate.valid && submitted
												}"
											/>
										</div>
									</div>
									<div class="col-12">
										<label class="required">Message</label>
										<div class="form-group">
											<ckeditor
												[data]="message"
												[disabled]="!isEditing"
												[editor]="Editor"
												(ready)="onReady($event)"
												(change)="onEditorChange($event)"
											></ckeditor>
										</div>
									</div>
									<div class="col-md-6" *ngIf="clientId == '16731dca-45e0-4cc5-a9f5-2f0a759db4af'">
										<label>Announcement For Clients</label>
										<div class="form-group">
											<select formControlName="clientId" class="form-control" (change)="onClientChange()">
												<option value="">-- select client --</option>
												<option *ngIf="clients?.length > 0" style="font-weight: 700" value="all">
													<b>All Clients</b>
												</option>
												<option [value]="c.id" *ngFor="let c of clients">
													{{ c.name }}
												</option>
											</select>
										</div>
									</div>
									<div class="col-md-6">
										<label class="required">Announcement For Users</label>
										<div class="form-group">
											<select formControlName="userId" class="form-control" (change)="onUserChange()">
												<option value="">-- select users --</option>
												<option *ngIf="users?.length > 0" style="font-weight: 700" value="all">
													<b>All Users</b>
												</option>
												<option [value]="u.id" *ngFor="let u of users">
													<span *ngIf="f.clientId?.value == 'all'">{{ u.client + ' - ' }}</span
													>{{ u.firstName + ' ' + u.lastName }}
												</option>
											</select>
										</div>
									</div>
									<div class="col-12">
										<button
											(click)="clearList()"
											class="pull-right btn btn-danger"
											*ngIf="annoucementFor?.length > 0 && isEditing"
										>
											Clear List
										</button>
										<table
											class="table table-bordered"
											*ngIf="annoucementFor?.length > 0"
											style="max-height: 300px; overflow: scroll"
										>
											<thead>
												<th *ngIf="clientId == '16731dca-45e0-4cc5-a9f5-2f0a759db4af'">Client</th>
												<th>User</th>
												<th *ngIf="isEditing">Action</th>
											</thead>
											<tbody>
												<tr *ngFor="let annoucement of annoucementFor">
													<td *ngIf="clientId == '16731dca-45e0-4cc5-a9f5-2f0a759db4af'">
														{{ annoucement.clientName }}
													</td>
													<td>{{ annoucement.userName }}</td>
													<td *ngIf="isEditing">
														<button
															(click)="removeUserFromAnnoucement(annoucement.userId)"
															class="btn btn-simple btn-danger btn-icon delete"
														>
															<i class="fa fa-times"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</form>
							<div class="row justify-content-center" *ngIf="isRejected">
								<div class="col-8">
									<h5>Rejection Reason</h5>
									<div class="form-group">
										<textarea readonly="readonly" class="form-control" [value]="item?.declinedReason"> </textarea>
									</div>
								</div>
							</div>
							<div
								id="accordion"
								role="tablist"
								aria-multiselectable="true"
								class="card-collapse col-12"
								*ngIf="item?.id"
							>
								<!-- Documents -->
								<div class="card card-plain">
									<div class="card-header" role="tab" id="headingDocs">
										<a
											data-toggle="collapse"
											data-parent="#accordion"
											href="#collapseDocs"
											aria-expanded="false"
											aria-controls="collapseDocs"
										>
											<h5>
												Documents ({{ item?.documents?.length }})
												<i class="nc-icon nc-minimal-down"></i>
											</h5>
										</a>
									</div>
									<div id="collapseDocs" class="collapse" role="tabpanel" aria-labelledby="headingDocs">
										<div class="card-body">
											<!-- Documents-->
											<div class="row justify-content-center" [hidden]="item?.documents?.length == 0">
												<div class="col-12">
													<h6>Documents</h6>
													<div class="table-responsive">
														<table class="table table-bordered">
															<thead>
																<th>Document</th>
																<th>Document Url</th>
																<th [hidden]="!isEditing">Action</th>
															</thead>
															<tbody>
																<tr *ngFor="let doc of item?.documents; let i = index">
																	<td>{{ doc.documentName }}</td>
																	<td>
																		<a [href]="doc.linkUrl" target="_blank">{{ doc.documentName }}</a>
																	</td>
																	<td [hidden]="!isEditing">
																		<button type="button" class="btn btn-link" (click)="deleteDocument(doc, i)">
																			Remove
																		</button>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<!-- Approver List -->
								<div class="card card-plain">
									<div class="card-header" role="tab" id="headingApprovers">
										<a
											data-toggle="collapse"
											data-parent="#accordion"
											href="#collapseApprover"
											aria-expanded="false"
											aria-controls="collapseApprover"
										>
											<h5>
												Approver List ({{ item?.allApprovers?.length }})
												<i class="nc-icon nc-minimal-down"></i>
											</h5>
										</a>
									</div>
									<div id="collapseApprover" class="collapse" role="tabpanel" aria-labelledby="headingApprovers">
										<div class="card-body">
											<div class="row justify-content-center">
												<div class="col-12">
													<client-approvers-list
														[hideHeading]="true"
														[hideCurrentApproveColumn]="false"
														[allApprovers]="item?.allApprovers"
														[currentApprovers]="item?.currentApprovers"
													>
														>
													</client-approvers-list>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- Comments -->
								<div class="card card-plain" *ngIf="currentStep || item?.comments?.length > 0">
									<div class="card-header" role="tab" id="headingComments">
										<a
											data-toggle="collapse"
											data-parent="#accordion"
											href="#collapseComments"
											aria-expanded="false"
											aria-controls="collapseComments"
										>
											<h5>
												Comments ({{ item?.comments?.length }})
												<i class="nc-icon nc-minimal-down"></i>
											</h5>
										</a>
									</div>
									<div id="collapseComments" class="collapse" role="tabpanel" aria-labelledby="headingComments">
										<div class="card-body">
											<div class="row justify-content-center card card-timeline card-plain">
												<div class="col-12">
													<app-fieldwork-comments
														#commentComponent
														[serviceType]="'open-close-period'"
														[form]="item"
														[canApprove]="canApprove"
														[currentApprover]="item?.createdBy"
														[comments]="item?.comments"
													>
													</app-fieldwork-comments>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row" style="margin-bottom: 40px" *ngIf="isEditing">
								<div class="col-10">
									<div
										class="justify-content-center"
										style="display: flex; margin-bottom: 20px; display: flex; margin-top: 20px"
									>
										<h6>Upload supporting documentation here</h6>
									</div>
									<div class="row">
										<div class="col-12">
											<div *ngFor="let item of filesArray.controls; let i = index">
												<div class="row">
													<!-- needs to be one per item -->
													<client-purchasing-file-upload
														class="col-md-12"
														[labelName]="item.value.label"
														(fileValueChanged)="onFileChanged($event, i)"
													>
													</client-purchasing-file-upload>
												</div>
											</div>
										</div>
										<div class="col-md-12">
											<div class="row">
												<div class="col-md-5">
													<button type="button" class="btn btn-link" (click)="addFile()">
														<i class="fas fa-plus-circle"> </i> Add Attachment
													</button>
												</div>
												<div class="col-md-5" [hidden]="this.filesArray.controls.length <= 1">
													<button type="button" class="btn btn-link" (click)="removeFile()">
														<i class="fas fa-minus-circle"> </i> Remove Attachment
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="card-footer" *ngIf="!item?.isCompleted">
								<div class="row">
									<div class="col-md-3" *ngIf="item?.isDraft && !isEditing" [hidden]="!canSubmitForApproval()">
										<button
											type="button"
											[disabled]="item?.isCompleted"
											(click)="submitForApproval()"
											class="btn btn-info btn-round"
										>
											Submit for Approval
										</button>
									</div>
								</div>
							</div>
							<div class="card-footer">
								<div style="margin-bottom: 30px" *ngIf="!isEditing">
									<client-workflow-approval
										[userId]="userId"
										[clientId]="clientId"
										[workflow]="this.item"
										[formId]="this.item?.id"
										[canApprove]="this.canApprove"
										(onRejectWorkflow)="rejectWorkflow($event)"
										[formType]="'announcement-form'"
										(onApproveWorkflow)="approveWorkflow($event)"
										[canSendBack]="
											item?.workflowStep != 'Completed' &&
											currentStep != 'Completed' &&
											currentStep != 'Workflow Started' &&
											canSendForReview()
										"
										[disabledCanSendBackBtn]="commentComponent?.newCommentText.length > 0"
										(sendBack)="sendBack()"
									>
									</client-workflow-approval>
								</div>
								<button *ngIf="isEditing" type="submit" (click)="onSubmit()" class="btn btn-info btn-round">
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
