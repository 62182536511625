import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { RefundEntryViewModel } from 'app/models/business-license/RefundEntryViewModel';
import { ContactViewModel } from 'app/models/water-utility/ContactViewModel';
import { ApplicationViewModel } from 'app/models/water-utility/applicationViewModel';

@Injectable({
	providedIn: 'root',
})
export class ApplicationFormService {
	private readonly urlPath = 'application';
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<ApplicationViewModel> {
		return this.httpClient
			.post<ApplicationViewModel>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}`,
				this.setupRequest(request)
			)
			.pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		for (let i = 0; i < request.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}
		return formData;
	}

	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${formId}`,
				this.setupRequest(request)
			)
			.pipe();
	}

	getForm(id: string, clientId: string): Observable<ApplicationViewModel> {
		return this.httpClient
			.get<ApplicationViewModel>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${id}`)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}water-utility/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<ApplicationViewModel> {
		return this.httpClient
			.post<ApplicationViewModel>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<ApplicationViewModel> {
		return this.httpClient
			.post<ApplicationViewModel>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${formId}/approve`,
				{
					userId,
				}
			)
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<ApplicationViewModel> {
		return this.httpClient
			.post<ApplicationViewModel>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${formId}/review`,
				{}
			)
			.pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${formId}/docs/${docId}`
		);
	}
	getApplications(clientId: string): Observable<ApplicationViewModel[]> {
		return this.httpClient
			.get<ApplicationViewModel[]>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}`)
			.pipe();
	}

	getSearchApplications(clientId: string, searchQuery: string): Observable<ApplicationViewModel[]> {
		return this.httpClient
			.get<ApplicationViewModel[]>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/search/${searchQuery}`
			)
			.pipe();
	}

	getFeeTypes(clientId: string, area: string): Observable<ApplicationViewModel[]> {
		return this.httpClient
			.get<ApplicationViewModel[]>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/feeTypes/${area}`
			)
			.pipe();
	}

	getAreas(clientId: string): Observable<string[]> {
		return this.httpClient
			.get<string[]>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/feeAreas`)
			.pipe();
	}

	createInvoice(clientId: string, applicationId: string): Observable<ApplicationViewModel> {
		return this.httpClient
			.post<ApplicationViewModel>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${applicationId}/invoices`,
				{}
			)
			.pipe();
	}

	createAllInvoice(clientId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/invoices`, {})
			.pipe();
	}

	createAllUnpaidInvoice(clientId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/unpaid-invoices`, {})
			.pipe();
	}

	emailAllInvoice(clientId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/email-invoices`, {})
			.pipe();
	}

	emailAllUnpaidInvoice(clientId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/email-unpaid-invoices`, {})
			.pipe();
	}

	generateErrorReport(clientId: string): Observable<any> {
		return this.httpClient
			.post<ApplicationViewModel>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/invoices/invalid`,
				{}
			)
			.pipe();
	}

	disableApplication(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${formId}`)
			.pipe();
	}

	refundInvoice(
		clientId: string,
		applicationId: string,
		invoiceId: number,
		model: RefundEntryViewModel
	): Observable<any> {
		return this.httpClient
			.post<RefundEntryViewModel>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${applicationId}/invoices/${invoiceId}/refunds`,
				model
			)
			.pipe();
	}

	getRefund(clientId: string, applicationId: string, invoiceId: number, refundId: number): Observable<any> {
		return this.httpClient
			.get<RefundEntryViewModel>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${applicationId}/invoices/${invoiceId}/refunds/${refundId}`
			)
			.pipe();
	}

	updateFee(clientId: string, applicationId: string, id: number, model: any): Observable<any[]> {
		return this.httpClient
			.post<any[]>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${applicationId}/fees/${id}`,
				model
			)
			.pipe();
	}

	markInvoicePaid(clientId: string, model: any): Observable<any[]> {
		return this.httpClient
			.post<any[]>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/mark-invoice-paid`, model)
			.pipe();
	}

	updateContact(clientId: string, applicationId: string, id: number, model: any): Observable<ContactViewModel[]> {
		return this.httpClient
			.post<ContactViewModel[]>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${applicationId}/contacts/${id}`,
				model
			)
			.pipe();
	}

	getbatch(clientId: string, batchType: string): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/batch/${batchType}`)
			.pipe();
	}

	emailInvoicesForApplication(clientId: string, applicationId: string): Observable<ApplicationViewModel> {
		return this.httpClient
			.post<any>(
				`${environment.apiUrl}water-utility/clients/${clientId}/${this.urlPath}/${applicationId}/email-application-invoices`,
				{}
			)
			.pipe();
	}
}
