import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from '../BaseFormService';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class LocalVicinityTravelService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/local-vicinity`, httpClient, utilityService);
	}

	createForm(clientId: string, request: any): Observable<any> {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		return this.httpClient.post<any>(`${this.getUrl(clientId)}`, formData).pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/${id}`, formData).pipe();
	}

	getFormsForClient(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/GetPreApprovalByClient`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
