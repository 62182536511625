import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import {
	CustomerInvoiceFormView,
	CustomerInvoicePaymentView,
} from 'app/models/miscellaneous-billing/CustomerInvoiceFormView';
import { RemainingOverpaymentView } from 'app/models/RemainingOverpaymentView';
import { CustomerFormView } from 'app/models/miscellaneous-billing/Customer-form';

@Injectable({
	providedIn: 'root',
})
export class CustomerInvoiceFromService {
	private readonly urlPath = `${environment.apiUrl}miscellaneous-billing/clients/`;

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/customer-invoice-form`, request).pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.urlPath}${clientId}/customer-invoice-form/${id}`, request).pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/customer-invoice-form/${id}`).pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/customer-invoice-form/${id}/decline`, rejectionReason)
			.pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/customer-invoice-form/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			this.urlPath
		}${clientId}/customer-invoice-form/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/customer-invoice-form/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/customer-invoice-form/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/customer-invoice-form/${id}`).pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/customer-invoice-form/${formId}/docs/${docId}`);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<CustomerInvoiceFormView> {
		return this.httpClient
			.post<CustomerInvoiceFormView>(`${this.urlPath}${clientId}/customer-invoice-form/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	search(clientId: string): Observable<CustomerInvoiceFormView[]> {
		return this.httpClient
			.get<CustomerInvoiceFormView[]>(`${this.urlPath}${clientId}/customer-invoice-form/search`)
			.pipe();
	}

	generateInvoice(clientId: string, id: string): Observable<any> {
		let url = `${this.urlPath}${clientId}/customer-invoice-form/invoice/${id}`;
		return this.httpClient.get<any>(url).pipe();
	}

	getPaymentHistory(clientId: string, id: any): Observable<CustomerInvoicePaymentView[]> {
		return this.httpClient
			.get<CustomerInvoicePaymentView[]>(`${this.urlPath}${clientId}/customer-invoice-form/item/${id}`)
			.pipe();
	}

	itemPayment(clientId: string, request: CustomerInvoicePaymentView): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/customer-invoice-form/item-payment`, request).pipe();
	}

	emailInvoice(clientId: string, id: string, isCreator: boolean): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/customer-invoice-form/${id}/email-invoice/${isCreator}`, {})
			.pipe();
	}

	export(clientId: string) {
		return this.httpClient
			.get(`${this.urlPath}${clientId}/customer-invoice-form/export`, {
				responseType: 'blob',
			})
			.pipe();
	}

	getLastInvoice(clientId: string, customerId: string): Observable<CustomerInvoiceFormView> {
		return this.httpClient
			.get<CustomerInvoiceFormView>(`${this.urlPath}${clientId}/customer-invoice-form/${customerId}/last-invoice`)
			.pipe();
	}

	addOverpaymentItem(clientId: string, formId: string, request: CustomerInvoicePaymentView): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/customer-invoice-form/${formId}/overpayment`, request)
			.pipe();
	}

	overpaymentbalance(clientId: string, customerId: string): Observable<RemainingOverpaymentView[]> {
		return this.httpClient
			.get<RemainingOverpaymentView[]>(
				`${this.urlPath}${clientId}/customer-invoice-form/${customerId}/overpaymentbalance`
			)
			.pipe();
	}

	overpaymentbalanceforrefund(clientId: string, customerId: string): Observable<RemainingOverpaymentView[]> {
		return this.httpClient
			.get<RemainingOverpaymentView[]>(
				`${this.urlPath}${clientId}/customer-invoice-form/${customerId}/overpaymentbalanceforrefund`
			)
			.pipe();
	}

	getcarryoveritems(clientId: string, customerId: string, formId: string): Observable<CustomerInvoiceFormView[]> {
		return this.httpClient
			.get<CustomerInvoiceFormView[]>(
				`${this.urlPath}${clientId}/customer-invoice-form/${customerId}/${formId}/carryoveritems`
			)
			.pipe();
	}

	InvoicesWithOverpayment(clientId: string): Observable<CustomerFormView[]> {
		return this.httpClient
			.get<CustomerFormView[]>(`${this.urlPath}${clientId}/customer-invoice-form/invoiceswithoverpayment`)
			.pipe();
	}

	getActiveForms(clientId: string, customerId: string): Observable<CustomerInvoiceFormView[]> {
		return this.httpClient
			.get<CustomerInvoiceFormView[]>(`${this.urlPath}${clientId}/customer-invoice-form/${customerId}/activeinvoices`)
			.pipe();
	}
	getRecurringForms(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.urlPath}${clientId}/customer-invoice-form/recurring-forms`).pipe();
	}

	getCustomerInvoiceForScheduling(clientId: string, data: any): Observable<CustomerInvoiceFormView[]> {
		return this.httpClient
			.post<CustomerInvoiceFormView[]>(`${this.urlPath}${clientId}/customer-invoice-form/manage/scheduling`, data)
			.pipe();
	}

	updateCustomerInvoiceForScheduling(clientId: string, message: any): Observable<CustomerInvoiceFormView[]> {
		return this.httpClient
			.post<CustomerInvoiceFormView[]>(
				`${this.urlPath}${clientId}/customer-invoice-form/manage/scheduling/update`,
				message
			)
			.pipe();
	}
}
