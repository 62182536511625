import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { Implementation } from 'app/models/form/Implementation';
import { ImplementationQuestion } from 'app/models/form/ImplementationQuestion';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class ImplementationPaymentService {
	private readonly urlPath = 'implementation-payment';
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}
	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`, request).pipe();
	}

	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`, request)
			.pipe();
	}

	getForm(clientId: string, formId: string): Observable<Implementation> {
		return this.httpClient
			.get<Implementation>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: any): Observable<Implementation> {
		return this.httpClient
			.post<Implementation>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<Implementation> {
		return this.httpClient
			.post<Implementation>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<Implementation> {
		return this.httpClient
			.post<Implementation>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`).pipe();
	}

	getQuestions(clientId: string, sectionId: string): Observable<ImplementationQuestion[]> {
		return this.httpClient.get<ImplementationQuestion[]>(
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/questions/${sectionId}`
		);
	}
}
