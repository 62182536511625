interface Scripts {
	name: string;
	src: string;
}
export const ProdScript: Scripts[] = [
	{ name: 'ze-snippet', src: 'https://static.zdassets.com/ekr/snippet.js?key=3b05d008-ef90-4a31-a958-765596deaff7' },
];

export const LazyLoadScript: Scripts[] = [
	{ name: 'pdfmake-pdfmake', src: './pdfmake-pdfmake.js' },
	{ name: 'pdfmake-vfs_fonts', src: './pdfmake-vfs_fonts.js' },
	{ name: 'exceljs', src: './exceljs.js' },
];
