import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { FieldWorkRequestCreator } from 'app/models/form/FieldWorkRequestCreator';
import { environment } from 'environments/environment';
import { FieldWorkRequestFormView } from 'app/models/form/FieldWorkRequestFormView';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { FieldWorkRequestCommentView } from 'app/models/form/FieldWorkRequestCommentView';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';

@Injectable({
	providedIn: 'root',
})
export class FieldWorkRequestFormService {
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: FieldWorkRequestCreator): Observable<FieldWorkRequestFormView> {
		return this.httpClient
			.post<FieldWorkRequestFormView>(`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request`, form)
			.pipe();
	}

	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}`, request)
			.pipe();
	}

	submitDeliverables(clientId: string, formId: string, request: any): Observable<FieldWorkRequestFormView> {
		return this.httpClient
			.post<FieldWorkRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}/deliverables`,
				request
			)
			.pipe();
	}

	uploadFile(clientId: string, formId, file: File, type): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}/docs?type=${type}`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			environment.apiUrl
		}forms/clients/${clientId}/fieldwork-request/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	getForm(id: string, clientId: string): Observable<FieldWorkRequestFormView> {
		return this.httpClient
			.get<FieldWorkRequestFormView>(`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${id}`)
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<FieldWorkRequestFormView> {
		return this.httpClient
			.post<FieldWorkRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<FieldWorkRequestFormView> {
		return this.httpClient
			.post<FieldWorkRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}/approve`,
				{
					userId,
				}
			)
			.pipe();
	}

	removeDeliverable(clientId: string, formId: string, documentId: string): Observable<any> {
		return this.httpClient
			.delete<any>(
				`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}/deliverables/${documentId}`
			)
			.pipe();
	}

	sendComment(
		clientId: string,
		formId: string,
		item: FieldWorkRequestCommentView,
		file: File
	): Observable<FieldWorkRequestCommentView> {
		const formData: FormData = new FormData();
		formData.append(file.name, file);

		formData.append('model', JSON.stringify(item));
		return this.httpClient
			.post<FieldWorkRequestCommentView>(
				`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}/comments`,
				formData
			)
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<FieldWorkRequestFormView> {
		return this.httpClient
			.post<FieldWorkRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}/review`,
				{}
			)
			.pipe();
	}

	delegatedTo(clientId: string, formId: string, userId: any): Observable<FieldWorkRequestFormView> {
		return this.httpClient
			.post<FieldWorkRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${formId}/delegate`,
				{
					userId,
				}
			)
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/fieldwork-request/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
