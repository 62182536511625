import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { environment } from 'environments/environment';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { BaseFormService } from '../BaseFormService';
import { BankRecConfigurationView } from 'app/models/general-ledger/BankRecConfigurationView';

@Injectable({
	providedIn: 'root',
})
export class OtherBankRecService extends BaseFormService {
	headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' });

	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}general-ledger/clients/$$clientId$$/forms/other-bank-rec`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getUnclearedRecordsByAccountFilter(clientId: string, month: number, fiscalYear: number, isActive, source: string, configurationId: number): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.getUrl(clientId)}/unclearByAccountFilter?month=${month}&fiscalYear=${fiscalYear}&isActive=${isActive}&source=${source}&configurationId=${configurationId}`
			)
			.pipe();
	}

	getBankRecords(clientId: string, month: number, fiscalYear: number, isActive, source: string, configurationId: number) {
		return this.httpClient
			.get<any>(
				`${this.getUrl(clientId)}?fiscalMonth=${month}&fiscalYear=${fiscalYear}&isActive=${isActive}&source=${source}&configurationId=${configurationId}`
			)
			.pipe();
	}

	createMatch(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/create-match`, request).pipe();
	}

	removeMatch(clientId: string, id: number): Observable<any> {
		return this.httpClient.delete<any>(`${this.getUrl(clientId)}/${id}/remove-match`).pipe();
	}

	GetAvailableYears(clientId: string, configurationId: number): Observable<number[]> {
		let url = `${this.getUrl(clientId)}/${configurationId}/available-years`;
		return this.httpClient.get<number[]>(url, { headers: this.headers }).pipe();
	}

	GetAvailableMonths(clientId: string, year: number, configurationId: number): Observable<number[]> {
		let url = `${this.getUrl(clientId)}/${year}/${configurationId}/available-months`;
		return this.httpClient.get<number[]>(url, { headers: this.headers }).pipe();
	}

	GetBankRecFormSetup(clientId: string, year: number, month: number, configurationId: number): Observable<any> {
		let url = `${this.getUrl(clientId)}/${year}/${month}/${configurationId}/setup`;
		return this.httpClient.get<any>(url, { headers: this.headers }).pipe();
	}

	exportUnclearTransactionToExcel(clientId: string, month: number, fiscalYear: number, isActive, configurationId: number) {
		return this.httpClient
			.get(
				`${this.getUrl(clientId)}/unclear-transactions/export?month=${month}&fiscalYear=${fiscalYear}&isActive=${isActive}&configurationId=${configurationId}`,
				{
					responseType: 'blob',
				}
			)
			.pipe();
	}

	getBankRecConfigurations(clientId: string): Observable<BankRecConfigurationView[]> {
		let url = `${this.getUrl(clientId)}/bankRecConfiguration`;
		return this.httpClient.get<BankRecConfigurationView[]>(url, { headers: this.headers }).pipe();
	}

	updateBankRecConfigurations(clientId: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/bankRecConfiguration`, request).pipe();
	}

	removeBankRecConfiguration(clientId: string, id: any): Observable<any> {
		return this.httpClient
			.delete<any>(`${this.getUrl(clientId)}/bankRecConfiguration/${id}`)
			.pipe();
	}

	getConfigurations(clientId: any): Observable<any> {
		return this.httpClient
			.get(`${this.getUrl(clientId)}/bank-rec-import-config`)
			.pipe();
	}
}
