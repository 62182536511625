import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import { TravelReimbursementView } from 'app/models/form/TravelReimbursementView';
import { BaseFormService } from '../BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class TravelReimbursementService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(
			`${environment.apiUrl}travel-reimbursement/clients/$$clientId$$/forms/travel-reimbursement-form`,
			httpClient,
			utilityService
		);
	}

	createForm(clientId: string, request: any): Observable<any> {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.travelExpenses.length; i++) {
			let randomText = this.utilityService.getRandomText(15);
			request.travelExpenses[i].fileHash = randomText;
			for (let att = 0; att < request.travelExpenses[i].attachments.length; att++) {
				formData.append(randomText, request.travelExpenses[i].attachments[att].file);
				request.travelExpenses[i].attachments[att] = null;
			}
		}
		formData.append('model', JSON.stringify(request));
		return this.httpClient.post<any>(`${this.getUrl(clientId)}`, formData).pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.travelExpenses.length; i++) {
			let randomText = this.utilityService.getRandomText(15);
			request.travelExpenses[i].fileHash = randomText;
			for (let att = 0; att < request.travelExpenses[i].attachments.length; att++) {
				formData.append(randomText, request.travelExpenses[i].attachments[att].file);
				request.travelExpenses[i].attachments[att] = null;
			}
		}
		formData.append('model', JSON.stringify(request));
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/${id}`, formData).pipe();
	}

	getAllForms(clientId: string, request: any): Observable<any[]> {
		return this.httpClient.post<any[]>(`${this.getUrl(clientId)}/all`, request).pipe();
	}

	deleteExpense(clientId: string, formId: string, id: string): Observable<TravelReimbursementView> {
		return this.httpClient.delete<TravelReimbursementView>(`${this.getUrl(clientId)}/statements/${id}`).pipe();
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<TravelReimbursementView> {
		return this.httpClient
			.post<TravelReimbursementView>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
