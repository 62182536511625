import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WorkflowStepView } from 'app/models/form/WorkflowStepView';
import { MatStepper } from '@angular/material/stepper';

@Component({
	selector: 'client-stepper-workflow',
	templateUrl: './stepper-workflow.component.html',
	styleUrls: ['./stepper-workflow.component.css'],
})
export class StepperWorkflowComponent implements OnInit {
	@ViewChild('stepper') private myStepper: MatStepper;
	@Input() workflowSteps: WorkflowStepView[];

	constructor() {}

	ngOnInit(): void {}

	ngOnChanges() {
		if (this.myStepper) {
			this.myStepper.reset();
		}
		setTimeout(() => {
			let anyCompleted = false;
			if (this.workflowSteps) {
				for (let step of this.workflowSteps) {
					if (step.isCompleted) {
						this.myStepper.next();
						anyCompleted = true;
					}
				}
			}

			if (!anyCompleted && this.myStepper?.selectedIndex) {
				if (this.workflowSteps?.length > 0) {
					this.myStepper.reset();
				}
			}
		}, 300);
	}

	viewApprovers() {}
}
