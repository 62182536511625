import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UtilityService } from '../utility.service';
import { APInvoiceView } from 'app/models/form/APInvoiceView';
import { environment } from 'environments/environment';
import { BaseFormService } from 'app/services/BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class APInvoiceFormService extends BaseFormService {
	private duplicateRequest = null;
	private response: Observable<APInvoiceView[]>;
	private showDuplicateInvoicePopup = new BehaviorSubject<boolean>(false);

	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/ap-invoice`, httpClient, utilityService);
	}

	convertToQuickPay(clientId: string, id: any, userId): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/convert-quick-form/`, {
				userId,
			})
			.pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.getUrl(clientId)}/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}
	convertInvoiceIntoInvoiceApproval(clientId: string, invoice: any) {
		const formData: FormData = new FormData();
		formData.append('SampleInvoice', invoice);
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/convert-invoice-to-apInvoice`, formData).pipe();
	}

	showDuplicateInvoicePopupChange$ = this.showDuplicateInvoicePopup.asObservable();
	setShowDuplicateInvoicePopup(value: boolean) {
		this.showDuplicateInvoicePopup.next(value);
	}
}
