import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import {
	CustomerInvoiceFormView,
	CustomerInvoicePaymentView,
} from 'app/models/miscellaneous-billing/CustomerInvoiceFormView';

@Injectable({
	providedIn: 'root',
})
export class EmployeeActionFromService {
	private readonly urlPath = `${environment.apiUrl}hris/clients/`;

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee-action-form`, this.setupRequest(request))
			.pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.directDeposits.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.directDeposits[i].voidedCheck);
			request.directDeposits[i].voidedCheck = null;
		}
		formData.append('model', JSON.stringify(request));
		return formData;
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.urlPath}${clientId}/employee-action-form/${id}`, this.setupRequest(request))
			.pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/employee-action-form/${id}`).pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee-action-form/${id}/decline`, rejectionReason)
			.pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/employee-action-form/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			this.urlPath
		}${clientId}/employee-action-form/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/employee-action-form/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/employee-action-form/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/employee-action-form/${formId}/docs/${docId}`);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/employee-action-form/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<CustomerInvoiceFormView> {
		return this.httpClient
			.post<CustomerInvoiceFormView>(`${this.urlPath}${clientId}/employee-action-form/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
