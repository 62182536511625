import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from 'app/services/utility.service';

export class BaseApiService {
	constructor(protected urlPath: string, protected httpClient: HttpClient, protected utilityService: UtilityService) {
		this.urlPath = urlPath;
	}
	getUrl(clientId: string): string {
		return this.urlPath.replace('$$clientId$$', clientId.toString());
	}

	create(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}`, request).pipe();
	}

	update(clientId: string, id: any, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/${id}`, request).pipe();
	}

	get(clientId: string, id: any): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/${id}`).pipe();
	}

	getAll(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}`).pipe();
	}

	delete(clientId: string, id: any) {
		return this.httpClient.delete(`${this.getUrl(clientId)}/${id}`).pipe();
	}

	import(clientId: string, request: any) {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/import`, request).pipe();
	}
}
