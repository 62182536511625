import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { WorkOrderRequestFormView } from 'app/models/form/WorkOrderRequestFormView';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class WorkOrderRequestFormService {
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: WorkOrderRequestFormView): Observable<WorkOrderRequestFormView> {
		return this.httpClient
			.post<WorkOrderRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/work-order-request`,
				this.setupRequest(form)
			)
			.pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();
		formData.append('model', JSON.stringify(request));
		for (let i = 0; i < request.attachments.length; i++) {
			const name = `additional file -${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}
		return formData;
	}

	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(
				`${environment.apiUrl}forms/clients/${clientId}/work-order-request/${formId}`,
				this.setupRequest(request)
			)
			.pipe();
	}

	removeDocument(clientId: string, formId: string, documentId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${environment.apiUrl}forms/clients/${clientId}/work-order-request/${formId}/document/${documentId}`)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			environment.apiUrl
		}forms/clients/${clientId}/work-order-request/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	getForm(id: string, clientId: string): Observable<WorkOrderRequestFormView> {
		return this.httpClient
			.get<WorkOrderRequestFormView>(`${environment.apiUrl}forms/clients/${clientId}/work-order-request/${id}`)
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<WorkOrderRequestFormView> {
		return this.httpClient
			.post<WorkOrderRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/work-order-request/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<WorkOrderRequestFormView> {
		return this.httpClient
			.post<WorkOrderRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/work-order-request/${formId}/approve`,
				{
					userId,
				}
			)
			.pipe();
	}

	submitDeliverables(clientId: string, formId: string, request: any): Observable<WorkOrderRequestFormView> {
		const formData: FormData = new FormData();
		formData.append('model', JSON.stringify(request));

		return this.httpClient
			.post<WorkOrderRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/work-order-request/${formId}/deliverables`,
				formData
			)
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<WorkOrderRequestFormView> {
		return this.httpClient
			.post<WorkOrderRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/work-order-request/${formId}/review`,
				{}
			)
			.pipe();
	}

	delegatedTo(clientId: string, formId: string, userId: any): Observable<WorkOrderRequestFormView> {
		return this.httpClient
			.post<WorkOrderRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/work-order-request/${formId}/delegate`,
				{
					userId,
				}
			)
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/work-order-request/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
