<div class="row">
	<div class="col-md-12">
		<label>{{ labelName }}</label>
		<div class="fileinput fileinput-new input-group" data-provides="fileinput">
			<div
				class="form-control"
				style="width: 40%"
				[ngClass]="{ 'has-error': isRequired && submitted && !isValid }"
				data-trigger="fileinput"
			>
				<i class="glyphicon glyphicon-file fileinput-exists"></i>
				<span class="fileinput-filename"></span>
			</div>
			<span class="input-group-addon btn btn-default btn-file">
				<span class="fileinput-new">Select file</span>
				<span class="fileinput-exists">Change</span>
				<input type="file" name="..." (change)="onFileChange($event)" [disabled]="isDisabled" />
			</span>
			<a
				href="#"
				(click)="onFileChange(null)"
				class="input-group-addon btn btn-default fileinput-exists"
				data-dismiss="fileinput"
				>Remove</a
			>
		</div>
	</div>
</div>
