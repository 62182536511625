import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { OperatingExpenseBudgetView } from 'app/models/form/budget/OperatingExpenseBudgetView';
import { map } from 'rxjs/operators';
import { UtilityService } from 'app/services/utility.service';
import { OperatingExpenseBudgetOverview } from 'app/models/form/budget/OperatingExpenseBudgetView';
import { BaseFormService } from 'app/services/BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class OperatingExpenseBudgetServices extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/operating-expense-budget`, httpClient, utilityService);
	}

	getBudgetForDepartment(clientId: string, departmentId: any, fiscalYear: number, copyPriorYearBudget: boolean): Observable<OperatingExpenseBudgetView> {
		return this.httpClient
			.get<OperatingExpenseBudgetView>(`${this.getUrl(clientId)}/departments/${departmentId}?fiscalyear=${fiscalYear}&copyPriorYearBudget=${copyPriorYearBudget}`)
			.pipe(
				map((data) => {
					if (data.id == '00000000-0000-0000-0000-000000000000') {
						data.id = '00000000-0000-0000-0000-000000000000';
					}
					return data;
				})
			);
	}

	getAvailableBudgets(clientId: string, fiscalyear: number): Observable<OperatingExpenseBudgetView[]> {
		return this.httpClient.get<OperatingExpenseBudgetView[]>(`${this.getUrl(clientId)}/available?fiscalyear=${fiscalyear}`);
	}

	getBudgetOverviewByYear(clientId: string, departmentId, year): Observable<OperatingExpenseBudgetOverview[]> {
		return this.httpClient
			.get<OperatingExpenseBudgetOverview[]>(`${this.getUrl(clientId)}/departments/${departmentId}/overview/${year}`)
			.pipe();
	}

	getBudgetOverview(clientId: string, departmentId): Observable<OperatingExpenseBudgetOverview[]> {
		return this.httpClient
			.get<OperatingExpenseBudgetOverview[]>(`${this.getUrl(clientId)}/departments/${departmentId}/overview`)
			.pipe();
	}

	getBudgetOverviewForFund(clientId: string, fundId, year?): Observable<OperatingExpenseBudgetOverview[]> {
		return this.httpClient
			.get<OperatingExpenseBudgetOverview[]>(`${this.getUrl(clientId)}/funds/${fundId}/overview?year=${year}`)
			.pipe();
	}

	getBudgetDetailsByYear(clientId: string, year): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/budgetdetail/${year}`).pipe();
	}

	getOperatingExpenseBudgetDetailByYear(clientId: string, year): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/operatingExpenseBudgetDetailByYear/${year}`).pipe();
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/${id}/review`, {}).pipe();
	}
	revertOperatingExpenseBudgetForm(clientId: string, id: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.getUrl(clientId)}/${id}/RevertOperatingExpenseForm`, {}).pipe();
	}
}
