import { Component, OnInit } from '@angular/core';
import { CreateAnnouncementView } from 'app/models/announcements/CreateAnnouncementView';
import { User } from 'app/models/User';
import { AnnouncementService } from 'app/services/Announcement/announcement.service';
import { LoadingService } from 'app/services/loading.service';
import { NotificationService } from 'app/services/notification.service';
import { UserService } from 'app/services/user.service';
import { UtilityService } from 'app/services/utility.service';

@Component({
	selector: 'app-announcements-view',
	templateUrl: './announcements-view.component.html',
	styleUrls: ['./announcements-view.component.css'],
})
export class AnnouncementsViewComponent implements OnInit {
	private clientId: string;
	private userId: string;
	allUsers: User[];
	data: CreateAnnouncementView[];

	constructor(
		private loadingService: LoadingService,
		private notifyService: NotificationService,
		private announcementService: AnnouncementService,
		public utilityService: UtilityService,
		private userService: UserService
	) {
		this.clientId = this.userService.currentUserValue.user.clientId;
		this.userId = this.userService.currentUserValue.user.id;
	}

	ngOnInit(): void {
		this.loadingService.OnLoading.emit(true);
		const allusersRequest = this.userService.getUsers().toPromise();
		const annoucementRequest = this.announcementService.getAnnouncementByUserId(this.clientId, this.userId).toPromise();

		Promise.all([allusersRequest, annoucementRequest]).then(
			([allusersResponse, annoucementResponse]) => {
				this.allUsers = allusersResponse;
				this.data = annoucementResponse;
				this.loadingService.OnLoading.emit(false);
			},
			(error) => {
				this.loadingService.OnLoading.emit(false);
				this.notifyService.showError('Unable to load list.', 'Error');
			}
		);
	}

	markAsRead(announcementForId, index) {
		this.loadingService.OnLoading.emit(true);
		this.callMarkAsRead(announcementForId, index);
	}

	markAllAsRead() {
		this.data.forEach((announcement, index) => {
			this.loadingService.OnLoading.emit(true);
			this.callMarkAsRead(announcement.announcementFors[0].id, index);
		});
	}

	callMarkAsRead(announcementForId: string, index: number) {
		this.announcementService.announcementMarkAsRead(this.clientId, announcementForId, this.userId).subscribe(
			(response) => {
				this.loadingService.OnLoading.emit(false);
				this.data[index].announcementFors[0].isRead = true;
				localStorage.setItem('unreadAnnouncements', this.data.filter((x) => !x.announcementFors[0].isRead).length + '');
			},
			(error) => {
				this.loadingService.OnLoading.emit(false);
				this.notifyService.showError('Unable mark as read', 'Error');
			}
		);
	}

	markAsArchive(announcementForId, index) {
		this.loadingService.OnLoading.emit(true);
		this.callMarkAsArchive(announcementForId, index);
	}

	markAllAsArchive() {
		this.data.forEach((announcement, index) => {
			this.loadingService.OnLoading.emit(true);
			this.callMarkAsArchive(announcement.announcementFors[0].id, index);
		});
	}

	callMarkAsArchive(announcementForId: string, index: number) {
		this.announcementService.announcementMarkAsArchive(this.clientId, announcementForId, this.userId).subscribe(
			(response) => {
				this.loadingService.OnLoading.emit(false);
				this.data.splice(index, 1);
				localStorage.setItem('unreadAnnouncements', this.data.filter((x) => !x.announcementFors[0].isRead).length + '');
			},
			(error) => {
				this.loadingService.OnLoading.emit(false);
				this.notifyService.showError('Unable mark as read', 'Error');
			}
		);
	}

	getUserEmail(userId) {
		let user = this.allUsers.find((x) => x.id == userId);
		return user?.email;
	}
}
