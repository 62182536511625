import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import { TuitionReimbursementPaymentView } from 'app/models/form/TuitionReimbursementPaymentView';
import { BaseFormService } from '../BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class TuitionReimbursementPaymentService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/tuition-reimbursement-payment`, httpClient, utilityService);
	}

	getFormsForClient(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/GetPreApprovalByClient`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
