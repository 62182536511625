import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from 'app/services/BaseFormService';
import { environment } from 'environments/environment';
import { CheckRequestView } from 'app/models/form/CheckRequestView';
import { MultiplePaymentStatementView, MultiplePaymentView } from 'app/models/form/MultiplePaymentView';
import { ApInvoiceDuplicateSearchRequest } from 'app/models/form/ApInvoiceDuplicateSearchRequest';

@Injectable({
	providedIn: 'root',
})
export class MultiplePaymentServices extends BaseFormService {
	private duplicateRequest = null;
	private response: Observable<MultiplePaymentStatementView[]>;
	private showDuplicateInvoicePopup = new BehaviorSubject<boolean>(false);
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/multiple-payment`, httpClient, utilityService);
	}

	createForm(clientId: string, form: any): Observable<any> {
		const formData: FormData = new FormData();
		for (let i = 0; i < form.statements.length; i++) {
			let randomText = this.utilityService.getRandomText(15);
			form.statements[i].fileHash = randomText;
			formData.append(randomText, form.statements[i].attachment);
			form.statements[i].attachment = null;
		}
		formData.append('model', JSON.stringify(form));
		return this.httpClient.post<any>(`${this.getUrl(clientId)}`, formData).pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.statements.length; i++) {
			let randomText = this.utilityService.getRandomText(15);
			request.statements[i].fileHash = randomText;
			formData.append(randomText, request.statements[i].attachment);
			request.statements[i].attachment = null;
		}
		formData.append('model', JSON.stringify(request));
		return this.httpClient.put<any>(`${this.getUrl(clientId)}/${id}`, formData).pipe();
	}

	deleteStatement(clientId: string, formId: string, id: string): Observable<MultiplePaymentView> {
		return this.httpClient
			.delete<MultiplePaymentView>(`${this.getUrl(clientId)}/${formId}/statements/${id}`)
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<CheckRequestView> {
		return this.httpClient
			.post<CheckRequestView>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.getUrl(clientId)}/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	scanMultiplePaymentReceiptDetails(clientId: string, invoice: any) {
		const formData: FormData = new FormData();
		formData.append('SampleInvoice', invoice);
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/scan-multiple-payment-receipt-details`, formData).pipe();
	}
	
	getMultiplePaymentReport(clientId: any): Observable<any> {
		return this.httpClient
			.get<any>(`${this.getUrl(clientId)}/multiple-payment-transactions-report`)
			.pipe();
	}

	showDuplicateInvoicePopupChange$ = this.showDuplicateInvoicePopup.asObservable();
	setShowDuplicateInvoicePopup(value: boolean) {
		this.showDuplicateInvoicePopup.next(value);
	}
}
