import { ValidatorFn, AbstractControl } from '@angular/forms';

declare var moment: any;
export function datePickerValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		let date;
		if (control.value) {
			try {
				date = moment(new Date(control.value));
			} catch (e) {
				console.log(e);
			}
		}
		return date ? null : { datePicker: false };
	};
}
