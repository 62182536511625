import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { BidForm } from 'app/models/form/BidForm';
import { EDynamicFormInputType } from 'app/models/form/DynamicFormView';
import { DynamicFormUtilityServices } from '../dynamic-form-utility.service';

@Injectable({
	providedIn: 'root',
})
export class BidFormService {
	private readonly urlPath = 'bid';
	constructor(
		private httpClient: HttpClient,
		private utilityService: UtilityService,
		private dynamicFormUtilityServices: DynamicFormUtilityServices
	) {}

	createForm(clientId: string, request: any, prefix: string): Observable<BidForm> {
		return this.httpClient
			.post<BidForm>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`,
				this.setupRequest(request, prefix)
			)
			.pipe();
	}

	private setupRequest(request: any, prefix: string) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		for (let i = 0; i < request.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}

		//Append Addtional fields Files
		this.dynamicFormUtilityServices.appendDocuments(formData, prefix, request);

		return formData;
	}

	updateForm(clientId: string, formId: string, request: any, prefix: string): Observable<any> {
		return this.httpClient
			.put<any>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`,
				this.setupRequest(request, prefix)
			)
			.pipe();
	}

	getForm(id: string, clientId: string): Observable<BidForm> {
		return this.httpClient.get<BidForm>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`).pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/docs/${docId}`
		);
	}
	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<BidForm> {
		return this.httpClient
			.post<BidForm>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<BidForm> {
		return this.httpClient
			.post<BidForm>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<BidForm> {
		return this.httpClient
			.post<BidForm>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	exportToPdf(clientId: string, formId: string): any {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/export`, {
				responseType: 'blob',
			})
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
