import { Component, Input, OnInit } from '@angular/core';
import { WorkflowApproverView } from 'app/models/form/WorkflowApprover';

@Component({
	selector: 'client-approvers-list',
	templateUrl: './approvers-list.component.html',
	styleUrls: ['./approvers-list.component.css'],
})
export class ApproversListComponent implements OnInit {
	@Input() allApprovers: WorkflowApproverView[];
	@Input() currentApprovers: WorkflowApproverView[];
	@Input() hideHeading: boolean;
	@Input() hideCurrentApproveColumn: boolean;

	constructor() {}

	ngOnInit(): void {}

	isNextApprover(workflowApprover: WorkflowApproverView) {
		if (this.currentApprovers && this.currentApprovers.length > 0) {
			const item = this.currentApprovers.find(
				(t) => t.userId == workflowApprover.userId && t.workflowStep == workflowApprover.workflowStep
			);
			return item != undefined;
		}
		return false;
	}
}
