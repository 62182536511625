import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { UtilityService } from '../utility.service';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { TimeSheetCommentView } from 'app/models/hris/TimeSheetCommentView';

@Injectable({
	providedIn: 'root',
})
export class HrisTimeSheetService {
	private readonly urlPath = `${environment.apiUrl}hris/clients/`;

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/timesheet`, request).pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.urlPath}${clientId}/timesheet/${id}`, request).pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/timesheet/${id}`).pipe();
	}

	findForm(clientId: string, employeeId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/timesheet?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/timesheet/${id}/decline`, rejectionReason).pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/timesheet/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.urlPath}${clientId}/timesheet/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/timesheet/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/timesheet/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/timesheet/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/timesheet/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	holidaySchedules(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/timesheet/holidaySchedules`).pipe();
	}

	getCommentsByFormId(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/timesheet/comments?id=${id}`).pipe();
	}

	getCommentsByDates(clientId: string, startDate: any, endDate: any, employeeId: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/timesheet/comments?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}

	sendComment(clientId: string, item: TimeSheetCommentView, file: File): Observable<TimeSheetCommentView> {
		let url = `${environment.apiUrl}hris/clients/${clientId}/timesheet/comments`;

		const formData: FormData = new FormData();
		if (file !== null && file !== undefined) {
			formData.append(file.name, file);
		}

		formData.append('model', JSON.stringify(item));
		return this.httpClient.post<TimeSheetCommentView>(url, formData).pipe();
	}

	dashboardHoursReport(clientId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/timesheet/dashboard-hours-report?startDate=${startDate}&endDate=${endDate}`)
			.pipe();
	}

	getAllWorkflowForms(clientId: string, employeeId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/timesheet/workflow-forms?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}
	getTimesheetEntries(clientId: string, employeeId: string, startDate: any, endDate: any): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/timesheet/timesheetFormEntries?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`
			)
			.pipe();
	}
}
