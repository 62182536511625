import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { PurchasingView } from '../../models/form/PurchasingView';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { environment } from '../../../environments/environment';
import { DynamicFormUtilityServices } from '../dynamic-form-utility.service';

@Injectable({
	providedIn: 'root',
})
export class PurchasingFormService {
	public readonly contractServiceExemptionTypeDadeCity = [
		{
			id: 1,
			text:
				'Although there exists more than one responsible source, a competitive process cannot reasonably be used, or if used,\n' +
				"will result in a substantially higher cost to the City, will otherwise injure the City's financial interests or will\n" +
				"substantially impede the City's administrative functions or the delivery of services to the public",
		},
		{
			id: 2,
			text:
				'A particular material or service is required to maintain interchangeability or compatibility as a part of an existing' +
				'integrated system',
		},
		{
			id: 3,
			text: 'A particular material or service is required to maintain standardization for the purpose of reducing financial investment or simplifying administration',
		},
		{
			id: 4,
			text: 'The material is perishable',
		},
		{
			id: 5,
			text: 'A particular material is required to match materials in use, so as to produce visual harmony',
		},
		{
			id: 6,
			text: 'A particular material is prescribed by a professional advisor or consultant',
		},
		{
			id: 7,
			text: 'A particular material is required to enable use by a specific individual',
		},
		{
			id: 8,
			text: 'The material or service is the subject of a change order',
		},
		{
			id: 9,
			text: 'Regulation services such as telephone, internet, electricity and other similar services',
		},
		{
			id: 10,
			text: 'Specific types of disbursements such as professional memberships, legal advertising, training, and postage',
		},
		{
			id: 11,
			text: 'Agreements approved by the City Commission between the City and non-profit organizations or governmental entities including the procurement, transfer, sale or exchange of goods and/or services',
		},
		{
			id: 12,
			text: 'Artistic Services: The rendering by a contractor of its time and effort to create or perform an artistic work in the fields of music, dance, drama, folk are, creative writing, painting, sculpture, photography, graphic arts, craft arts, industrial design, costume design, fashion design, motion pictures, television, radio, or tape and sound recording',
		},
		{
			id: 13,
			text: 'Service required by proprietary ownership such as CSX Railroad carrier, original equipment manufacturers (OEM)',
		},
		{
			id: 14,
			text: 'Purchase of construction materials included in the scope of an awarded construction contract in order to realize sales tax savings, in accordance with Section 212.08(6), F.S.',
		},
		{
			id: 15,
			text: 'The noncompetitive procurement of contracts for legal, medical, certified public accounting, or other professional services is authorized by negotiation of the City Commission with organizations or persons on the basis of experience, skill and financial capacity to perform',
		},
	];

	private readonly urlPath = 'purchasing';

	constructor(
		private httpClient: HttpClient,
		private utilityService: UtilityService,
		private dynamicFormUtilityServices: DynamicFormUtilityServices
	) {}

	createForm(clientId: string, request: any, prefix: string, dynamicPrefixLabel: string): Observable<PurchasingView> {
		return this.httpClient
			.post<PurchasingView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`,
				this.setupRequest(request, prefix, dynamicPrefixLabel)
			)
			.pipe();
	}

	private setupRequest(request: any, prefix: string, dynamicPrefixLabel: string) {
		const formData: FormData = new FormData();
		formData.append('existingContractFile', request.existingContractFile);
		formData.append('writtenQuote1File', request.writtenQuote1File);
		formData.append('writtenQuote2File', request.writtenQuote2File);
		formData.append('writtenQuote3File', request.writtenQuote3File);
		formData.append('rfpDocumentFile', request.rfpDocumentFile);
		formData.append('proposalSubmittedFile', request.proposalSubmittedFile);
		formData.append('boardApprovalFile', request.boardApprovalFile);
		formData.append('signedContractFile', request.signedContractFile);
		formData.append('soleSourceLetterFile', request.soleSourceLetterFile);
		formData.append('soleSourceOtherDocumentationFile', request.soleSourceOtherDocumentationFile);
		formData.append('piggybackRFPDocumentFile', request.piggybackRFPDocumentFile);
		formData.append('piggybackContractFile', request.piggybackContractFile);
		formData.append('exemptDocumentFile', request.exemptDocumentFile);
		formData.append('exemptOtherDocument', request.exemptOtherDocument);
		formData.append('model', JSON.stringify(request));

		for (let i = 0; i < request.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}

		//Append Procurement Method Configuration Files
		for (let i = 0; i < request.procurementMethodConfiguration.procurementMethodConfigurations.length; i++) {
			const configuration = request.procurementMethodConfiguration.procurementMethodConfigurations[i];

			const isInputTypeFile = configuration.procurementMethodFormTypeId == 1;
			if (isInputTypeFile) {
				formData.append(configuration.tempLabel.slice(prefix.length), request[configuration.tempLabel]);
			}
		}

		//Append Addtional fields Files
		this.dynamicFormUtilityServices.appendDocuments(formData, dynamicPrefixLabel, request);

		return formData;
	}

	updateForm(
		clientId: string,
		formId: string,
		request: any,
		prefix: string,
		dynamicPrefixLabel: string
	): Observable<any> {
		return this.httpClient
			.put<any>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`,
				this.setupRequest(request, prefix, dynamicPrefixLabel)
			)
			.pipe();
	}

	getForm(id: string, clientId: string): Observable<PurchasingView> {
		return this.httpClient
			.get<PurchasingView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<PurchasingView> {
		return this.httpClient
			.post<PurchasingView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<PurchasingView> {
		return this.httpClient
			.post<PurchasingView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<PurchasingView> {
		return this.httpClient
			.post<PurchasingView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	exportToPdf(clientId: string, formId: string): any {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/export`, {
				responseType: 'blob',
			})
			.pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/docs/${docId}`
		);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	getConfigurations(clientId: any): Observable<any> {
		return this.httpClient
			.get<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/get-configuration`)
			.pipe();
	}
	downloadImportItemSampleFile(clientId: string, isInventoryItem: boolean) {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/import-item-file/${isInventoryItem}`, {
				responseType: 'blob',
			})
			.pipe();
	}
}
