<div id="reader" class="col-12 no-padding">
	<ol class="row">
		<li *ngFor="let item of comments" class="col-12 no-padding">
			<div class="comment_box">
				<div class="inside_comment">
					<div class="comment-meta">
						<div class="commentsuser">{{ item.createdBy }}</div>
						<div class="comment_date">{{ item.createdDate | date : 'medium' }}</div>
					</div>
					<div class="comment-meta" *ngIf="item.modifiedDate">
						<div class="comment_date">
							<small>(edited): {{ item.modifiedDate | date : 'medium' }}</small>
						</div>
					</div>
				</div>
				<div *ngIf="!item.isEditing">
					<div class="comment-body" *ngIf="item.documentName">
						<p>
							<a [href]="item.linkUrl" target="_blank">{{ item?.documentName }}</a>
						</p>
						<hr />
					</div>
					<div class="comment-body">
						<p>{{ item.comment }}</p>
					</div>
					<div class="reply" style="margin-top: 20px" *ngIf="allowComment()">
						<a class="btn-link small" href="javascript:void(0)" (click)="addComment(item)">Reply</a>
						<a class="btn-link small" href="javascript:void(0)" *ngIf="allowEdit(item)" (click)="editComment(item)"
							>Edit</a
						>
					</div>
				</div>
				<div *ngIf="item.isEditing">
					<div class="commentfields">
						<textarea [(ngModel)]="item.comment" maxlength="1200" class="comment-textarea required"></textarea>
					</div>
					<div class="commentfields col-md-10">
						<div class="row">
							<div class="col-md-4 offset-1">
								<input class="commentbtn" type="button" (click)="submitComment(item)" value="Save Comment" />
							</div>
							<div class="col-md-3">
								<input class="commentbtn" type="reset" value="Cancel" (click)="cancelEdit(item)" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<ul class="children" *ngIf="item.comments.length > 0">
				<li *ngFor="let subItem of item.comments" class="col-12 no-padding">
					<div class="comment_box" *ngIf="!subItem.isEditing">
						<div class="inside_comment">
							<div class="comment-meta">
								<div class="commentsuser">{{ subItem.createdBy }}</div>
								<div class="comment_date">{{ subItem.createdDate | date : 'medium' }}</div>
							</div>
							<div class="comment-meta" *ngIf="subItem.modifiedDate">
								<div class="comment_date">
									<small>(edited): {{ subItem.modifiedDate | date : 'medium' }}</small>
								</div>
							</div>
						</div>
						<div class="comment-body">
							<p>{{ subItem.comment }}</p>
						</div>
						<div class="reply" style="margin-top: 20px" *ngIf="allowComment()">
							<a class="btn-link" href="javascript:void(0)" *ngIf="allowEdit(subItem)" (click)="editComment(subItem)"
								>Edit</a
							>
						</div>
					</div>
					<div *ngIf="subItem.isEditing">
						<div class="commentfields">
							<label>Reply <span>*</span></label>
							<textarea [(ngModel)]="subItem.comment" maxlength="1200" class="comment-textarea required"></textarea>
						</div>
						<div class="commentfields">
							<div class="row">
								<div class="col-md-3 offset-1">
									<input
										class="commentbtn"
										type="button"
										(click)="subItem.isReply = true; submitComment(item)"
										value="Save Reply"
									/>
								</div>
								<div class="col-md-3 offset-1">
									<input class="commentbtn" type="reset" value="Cancel" (click)="removeComment(item)" />
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</li>
	</ol>
</div>

<div id="respond" *ngIf="allowComment()">
	<h3>Add Comment</h3>
	<div id="commentForm" class="cmxform">
		<div class="commentfields" *ngIf="showFile">
			<label>Attachment</label>
			<div class="fileinput fileinput-new input-group" data-provides="fileinput">
				<div class="form-control" style="width: 40%" data-trigger="fileinput">
					<i class="glyphicon glyphicon-file fileinput-exists"></i>
					<span class="fileinput-filename"></span>
				</div>
				<span class="input-group-addon btn btn-default btn-file">
					<span class="fileinput-new">Select file</span>
					<span class="fileinput-exists">Change</span>
					<input type="file" name="..." (change)="onFileChange($event)" />
				</span>
				<a
					href="#"
					(click)="onFileChange(null)"
					class="input-group-addon btn btn-default fileinput-exists"
					data-dismiss="fileinput"
					>Remove</a
				>
			</div>
		</div>
		<div class="commentfields">
			<label>Comments <span style="color: red">*</span></label>
			<textarea class="comment-textarea required" [(ngModel)]="newCommentText" maxlength="1200"></textarea>
		</div>
		<div class="commentfields">
			<input class="commentbtn" type="button" (click)="newComment()" value="Post Comment" />
		</div>
	</div>
</div>
<div class="col-12" *ngIf="!allowComment() && comments && comments?.length == 0">No comments</div>
