import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class ProjectGrantTaskService {
	headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' });
	private readonly urlPath = `${environment.apiUrl}project-grant/clients/`;
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) { }

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/project-grant-task`, this.setupRequest(request))
			.pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		return formData;
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.urlPath}${clientId}/project-grant-task/${id}`, this.setupRequest(request))
			.pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/project-grant-task/${id}`).pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/project-grant-task/${id}/decline`, rejectionReason)
			.pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/project-grant-task/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.urlPath}${clientId}/project-grant-task/users/${userId}?${this.utilityService.objectToQueryString(
			search
		)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/project-grant-task/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/project-grant-task/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/project-grant-task/${formId}/docs/${docId}`);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/project-grant-task/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/project-grant-task/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getAllTask(clientId: string, projectGrantId: string, isClosed: boolean): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${this.urlPath}${clientId}/project-grant-task/get-all-task/${projectGrantId}/${isClosed}`, {
				headers: this.headers,
			})
			.pipe();
	}

	getProjectsAllTasks(clientId: string, isClosed: boolean): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${this.urlPath}${clientId}/project-grant-task/get-projects-all-tasks/${isClosed}`, {
				headers: this.headers,
			})
			.pipe();
	}

	getRollforward(clientId: string, projectGrantId: string, year: number, month: number): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${this.urlPath}${clientId}/project-grant-task/rollforward/${projectGrantId}/${year}/${month}`)
			.pipe();
	}
	getTaskTransactionDetail(clientId: string, taskId: string, year: number, month: number): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/project-grant-task/task-transaction-detail/${taskId}/${year}/${month}`)
			.pipe();
	}
	getTasksByAccount(clientId: string,projectGrantId: string, account: string, isActive: boolean = true): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${this.urlPath}${clientId}/project-grant-task/get-all-by-account/${projectGrantId}/${account}/${isActive}`)
			.pipe();
	}
}
