import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from '../BaseFormService';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class IndividualRefundService extends BaseFormService {
	private showDuplicateInvoicePopup = new BehaviorSubject<boolean>(false);

	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/individual-refund-form`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	export(clientId: string, id: string): Observable<any> {
		return this.httpClient
			.get(`${this.getUrl(clientId)}/${id}/export`, {
				responseType: 'blob',
			})
			.pipe();
	}
	exportRefundData(clientId: string, id: string): Observable<any> {
		return this.httpClient
			.get(`${this.getUrl(clientId)}/${id}/refundDataExport`, {
				responseType: 'blob',
			})
			.pipe();
	}

	showDuplicateInvoicePopupChange$ = this.showDuplicateInvoicePopup.asObservable();
	setShowDuplicateInvoicePopup(value: boolean) {
		this.showDuplicateInvoicePopup.next(value);
	}
}
