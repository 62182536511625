import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { AclarianTaskView } from '../../models/form/AclarianTaskView';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class AclarianTaskFormService {
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: AclarianTaskView): Observable<AclarianTaskView> {
		const formData: FormData = new FormData();
		if (form.documents) {
			for (let i = 0; i < form.documents.length; i++) {
				const name = `attachment-${i + 1}`;
				formData.append(name, form.documents[i].file);
				form.documents[i].file = null;
				form.documents[i].name = name;
			}
		}

		formData.append('model', JSON.stringify(form));
		return this.httpClient
			.post<AclarianTaskView>(`${environment.apiUrl}forms/clients/${clientId}/task-request`, formData)
			.pipe();
	}

	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${environment.apiUrl}forms/clients/${clientId}/task-request/${formId}`, request)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			environment.apiUrl
		}forms/clients/${clientId}/task-request/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	getForm(id: string, clientId: string): Observable<AclarianTaskView> {
		return this.httpClient
			.get<AclarianTaskView>(`${environment.apiUrl}forms/clients/${clientId}/task-request/${id}`)
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<AclarianTaskView> {
		return this.httpClient
			.post<AclarianTaskView>(
				`${environment.apiUrl}forms/clients/${clientId}/task-request/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<AclarianTaskView> {
		return this.httpClient
			.post<AclarianTaskView>(`${environment.apiUrl}forms/clients/${clientId}/task-request/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	submitDeliverables(clientId: string, formId: string, request: any): Observable<AclarianTaskView> {
		const formData: FormData = new FormData();
		if (request.documents) {
			for (let i = 0; i < request.documents.length; i++) {
				const name = `deliverable-${i + 1}`;
				formData.append(name, request.documents[i].file);
				request.documents[i].file = null;
				request.documents[i].name = name;
			}
		}
		formData.append('model', JSON.stringify(request));

		return this.httpClient
			.post<AclarianTaskView>(
				`${environment.apiUrl}forms/clients/${clientId}/task-request/${formId}/deliverables`,
				formData
			)
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<AclarianTaskView> {
		return this.httpClient
			.post<AclarianTaskView>(`${environment.apiUrl}forms/clients/${clientId}/task-request/${formId}/review`, {})
			.pipe();
	}

	delegatedTo(clientId: string, formId: string, userId: any): Observable<AclarianTaskView> {
		return this.httpClient
			.post<AclarianTaskView>(`${environment.apiUrl}forms/clients/${clientId}/task-request/${formId}/delegate`, {
				userId,
			})
			.pipe();
	}
}
