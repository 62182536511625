<div class="main-content">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">
						Announcements
						<button [routerLink]="['/announcements/create']" style="font-size: 0.5em" class="btn btn-info btn-round">
							<i class="fa fa-plus"> </i> Add Announcement
						</button>
					</h4>
				</div>
				<div class="card-body">
					<div class="row" style="align-items: baseline">
						<div class="col-md-3 pl-10">
							Page Size:
							<select (change)="onPageSizeChanged()" [(ngModel)]="paginationPageSize" id="page-size">
								<option value="20" selected="">20</option>
								<option value="100">100</option>
								<option value="500">500</option>
								<option value="1000">1000</option>
							</select>
						</div>
						<div class="col-md-9 pr-10" style="text-align: right">
							<button class="btn btn-sm" (click)="clearFilters()">Clear Filters</button>

							<button class="btn btn-sm btn-success float-right" (click)="exportToExcel()">
								<i class="fa fa-file-excel-o"></i> Export
							</button>
						</div>
					</div>

					<ag-grid-angular
						style="width: 100%; height: calc(100vh - 35vh)"
						class="ag-theme-alpine"
						[rowData]="rowData"
						[masterDetail]="true"
						[sideBar]="sideBar"
						[keepDetailRows]="true"
						[keepDetailRowsCount]="keepDetailRowsCount"
						[animateRows]="true"
						[gridOptions]="gridOptions"
						[columnDefs]="columnDefs"
						rowSelection="multiple"
						[pagination]="true"
						[paginationPageSize]="paginationPageSize"
						[defaultColDef]="defaultColDef"
						[allowContextMenuWithControlKey]="true"
						[getContextMenuItems]="getContextMenuItems"
					>
					</ag-grid-angular>
				</div>

				<!-- end content-->
			</div>
			<!--  end card  -->
		</div>
		<!-- end col-md-12 -->
	</div>
	<!-- end row -->
</div>
