import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { ReceivingFormView } from 'app/models/inventory/receivingFormView';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { UtilityService } from '../utility.service';
import { BaseFormService } from '../BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class ReceivingFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}inventory/clients/$$clientId$$/receiving-form`, httpClient, utilityService);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	getReceivingFormByPo(clientId: string, poNumber: number): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/${poNumber}/get-receiving-by-po`, {}).pipe();
	}
}
