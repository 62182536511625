import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import { TravelPreApprovalView } from 'app/models/form/TravelPreApprovalView';
import { BaseFormService } from '../BaseFormService';
import { DynamicFormUtilityServices } from '../dynamic-form-utility.service';

@Injectable({
	providedIn: 'root',
})
export class SpecialEventPermitApplicationService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService,
		private dynamicFormUtilityServices: DynamicFormUtilityServices) {
		super(
			`${environment.apiUrl}recreation/clients/$$clientId$$/forms/special-event-permit-application-form`,
			httpClient,
			utilityService
		);
	}
	createForm(clientId: string, request: any, prefix: string): Observable<any> {
		return this.httpClient
			.post<any>(
				`${this.getUrl(clientId)}`,
				
				this.setupRequestCustom(request, prefix)
			)
			.pipe();
	}

	private setupRequestCustom(request: any, prefix: string) {
		const formData: FormData = new FormData();
		
		formData.append('model', JSON.stringify(request));

		for (let i = 0; i < request.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}
		this.dynamicFormUtilityServices.appendMultipleTypeDocuments(formData, prefix, request);

		return formData;
	}

	updateForm(clientId: string, formId: string, request: any, prefix: string): Observable<any> {
		return this.httpClient
			.put<any>(
				`${this.getUrl(clientId)}/${formId}`,
				this.setupRequestCustom(request, prefix)
			)
			.pipe();
	}

	getFormsForClient(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/GetPreApprovalByClient`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	
	generateSpecialEventApprovalLetter(clientId: string, formId: any): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/generate-special-event-approval-letter/${formId}`).pipe();
	}
	emailSpecialEventApprovalLetter(clientId: string, formId: any, toCCUser: boolean): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}/email-special-event-approval-letter/${formId}/${toCCUser}`).pipe();
	}
	finalizeFee(clientId: string, formId: any): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/finalize-fee/${formId}`, null).pipe();
	}
	itemPayment(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/item-payment`, request).pipe();
	}
	getAllApplications(clientId: string): Observable<any> {
		return this.httpClient.get<any>(`${this.getUrl(clientId)}`).pipe();
	}
}
