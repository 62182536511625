import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { TimeSheetEntryView, TimeSheetView } from '../../models/form/TimeSheetView';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TimeSheetService {
	private readonly urlPath = 'timesheet';
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: TimeSheetView): Observable<TimeSheetView> {
		return this.httpClient.post<TimeSheetView>(`${environment.apiUrl}forms/clients/${clientId}/timesheet`, form).pipe();
	}

	getForm(id: string, clientId: string): Observable<TimeSheetView> {
		return this.httpClient.get<TimeSheetView>(`${environment.apiUrl}forms/clients/${clientId}/timesheet/${id}`).pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<TimeSheetView> {
		return this.httpClient
			.post<TimeSheetView>(
				`${environment.apiUrl}forms/clients/${clientId}/timesheet/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<TimeSheetView> {
		return this.httpClient
			.post<TimeSheetView>(`${environment.apiUrl}forms/clients/${clientId}/timesheet/${formId}/approve`, {
				userId,
			})
			.pipe();
	}
	sendBack(clientId: string, formId: string): Observable<TimeSheetView> {
		return this.httpClient
			.post<TimeSheetView>(`${environment.apiUrl}forms/clients/${clientId}/timesheet/${formId}/review`, {})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			environment.apiUrl
		}forms/clients/${clientId}/timesheet/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	editForm(clientId: string, id: any, request: any) {
		return this.httpClient
			.put<TimeSheetView>(`${environment.apiUrl}forms/clients/${clientId}/timesheet/${id}`, request)
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<TimeSheetView> {
		return this.httpClient
			.post<TimeSheetView>(`${environment.apiUrl}forms/clients/${clientId}/timesheet/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	checkTimeSheet(clientId: string, userId: string, currentYear: number, payPeriod: number) {
		return this.httpClient
			.get<TimeSheetView>(
				`${environment.apiUrl}forms/clients/${clientId}/timesheet?userId=${userId}&year=${currentYear}&payPeriod=${payPeriod}`
			)
			.pipe();
	}

	uploadFile(clientId: string, formId, file: File, type): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${clientId}/timesheet/${formId}/docs?type=${type}`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}
}
