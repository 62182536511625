import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class ManualMeterReadFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(
			`${environment.apiUrl}utility-billing/clients/$$clientId$$/manual-meter-read-form`,
			httpClient,
			utilityService
		);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getMeterReadManualOnOffByFilter(clientId: string, filter: any): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.apiUrl}utility-billing/clients/${clientId}/manual-meter-read-form/filter/${filter}`
		);
	}

	getOnlyCustomerAccountsByFilter(clientId: string, filter: any): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.apiUrl}utility-billing/clients/${clientId}/manual-meter-read-form/filterAccounts/${filter}`
		);
	}
	
}
