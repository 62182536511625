import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'client-purchasing-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
	@Input() labelName: string;
	@Input() isRequired: boolean;
	@Input() submitted: boolean;
	@Input() isDisabled: boolean = false;
	@Output() fileValueChanged: EventEmitter<any> = new EventEmitter<any>();
	@Input() isValid: Boolean;

	constructor() {}

	ngOnInit(): void {}

	onFileChange($event: Event) {
		let file = null;
		if ($event) {
			file = ($event.target as HTMLInputElement).files[0];
		}

		this.fileValueChanged.emit(file);
	}
}
