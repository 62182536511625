import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { PCardView } from '../../models/form/PCardView';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PCardService {
	private readonly urlPath = 'p-card';
	private showDuplicateInvoicePopup = new BehaviorSubject<boolean>(false);

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) { }

	createForm(clientId: string, form: PCardView): Observable<PCardView> {
		const formData: FormData = new FormData();
		for (let i = 0; i < form.statements.length; i++) {
			let randomText = this.utilityService.getRandomText(15);
			form.statements[i].fileHash = randomText;
			formData.append(randomText, form.statements[i].attachment);
			form.statements[i].attachment = null;
		}
		if (form.monthlyStatement) {
			formData.append('monthlyStatement', form.monthlyStatement);
		}

		formData.append('model', JSON.stringify(form));
		return this.httpClient
			.post<PCardView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`, formData)
			.pipe();
	}

	getForm(id: string, clientId: string): Observable<PCardView> {
		return this.httpClient
			.get<PCardView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`)
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<PCardView> {
		return this.httpClient
			.post<PCardView>(
				`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<PCardView> {
		return this.httpClient
			.post<PCardView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${this.urlPath
			}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	editForm(clientId: string, id: any, request: any) {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.statements.length; i++) {
			let randomText = this.utilityService.getRandomText(15);
			request.statements[i].fileHash = randomText;
			formData.append(randomText, request.statements[i].attachment);
			request.statements[i].attachment = null;
		}

		if (request.monthlyStatement) {
			formData.append('monthlyStatement', request.monthlyStatement);
		}

		formData.append('model', JSON.stringify(request));
		return this.httpClient
			.post<PCardView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`, formData)
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<PCardView> {
		return this.httpClient
			.post<PCardView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	deleteStatement(clientId: string, formId: string, id: string): Observable<PCardView> {
		return this.httpClient
			.delete<PCardView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/statements/${id}`)
			.pipe();
	}

	deleteApprover(clientId: string, formId: any, userId: string): Observable<PCardView> {
		return this.httpClient
			.delete<PCardView>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approvers/${userId}`)
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}
	getPCardReport(clientId: any): Observable<any> {
		return this.httpClient
			.get<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/pcard-transactions-report`)
			.pipe();
	}
	updateCashPostingDate(clientId: string, id: any, cashPostingDate: Date): Observable<any> {
		const body = { cashPostingDate: cashPostingDate };
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/updateCashPostingDate/${id}`, body)
			.pipe();
	}

	showDuplicateInvoicePopupChange$ = this.showDuplicateInvoicePopup.asObservable();
	setShowDuplicateInvoicePopup(value: boolean) {
		this.showDuplicateInvoicePopup.next(value);
	}

	scanPCardReceiptDetails(clientId: string, invoice: any) {
		const formData: FormData = new FormData();
		formData.append('SampleInvoice', invoice);
		return this.httpClient.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/scan-pcard-receipt-details`, formData).pipe();
	}
}
