import { Component } from '@angular/core';

@Component({
	moduleId: module.id,
	selector: 'footer-cmp',
	templateUrl: 'footer.component.html',
})
export class FooterComponent {
	test: Date = new Date();
}
