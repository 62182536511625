import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AdditionFormService {
	private readonly urlPath = `${environment.apiUrl}capital-assets/clients/`;

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/additions`, this.setupAssetEditRequest(request))
			.pipe();
	}

	updateAsset(clientId: string, request: any): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/additions/update-asset`, this.setupAssetEditRequest(request))
			.pipe();
	}
	private setupAssetEditRequest(request: any) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		for (let i = 0; i < request.attachments.length; i++) {
			const name = `Other-${i + 1}`;
			formData.append(name, request.attachments[i].file);
			request.attachments[i].name = name;
		}
		for (let i = 0; i < request.assetTitleRegistration.length; i++) {
			const name = `Asset Title Registration-${i + 1}`;
			formData.append(name, request.assetTitleRegistration[i].file);
			request.assetTitleRegistration[i].name = name;
		}
		for (let i = 0; i < request.repairMaintenanceActivity.length; i++) {
			const name = `Repair Maintenance Activity-${i + 1}`;
			formData.append(name, request.repairMaintenanceActivity[i].file);
			request.repairMaintenanceActivity[i].name = name;
		}
		return formData;
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.urlPath}${clientId}/additions/${id}`, this.setupAssetEditRequest(request))
			.pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/additions/${id}`).pipe();
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/additions/${formId}/docs/${docId}`);
	}
	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/additions/${id}/decline`, rejectionReason).pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/additions/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.urlPath}${clientId}/additions/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/additions/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/additions/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/additions/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
