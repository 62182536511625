import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { environment } from 'environments/environment';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';

@Injectable({
	providedIn: 'root',
})
export class TransferFormService {
	private readonly urlPath = `${environment.apiUrl}capital-assets/clients/`;

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/transfer`, this.setupRequest(request)).pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		return formData;
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient.put<any>(`${this.urlPath}${clientId}/transfer/${id}`, this.setupRequest(request)).pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/transfer/${id}`).pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/transfer/${id}/decline`, rejectionReason).pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/transfer/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/transfer/${formId}/docs/${docId}`);
	}
	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.urlPath}${clientId}/transfer/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/transfer/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/transfer/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/transfer/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
