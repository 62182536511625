import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';

@Injectable({
	providedIn: 'root',
})
export class NewCustomerFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}utility-billing/clients/$$clientId$$/customer-form`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getCustomersByZoneId(clientId: string, zoneId: any): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${environment.apiUrl}utility-billing/clients/${clientId}/customer-form/byzone/${zoneId}`)
			.pipe();
	}

	getInvoicesByCustomerId(clientId: string, customerId: any): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${environment.apiUrl}utility-billing/clients/${clientId}/customer-form/invoices/${customerId}`)
			.pipe();
	}
}
