import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
	constructor() {
		super();
	}

	handleError(error: Error) {
		console.log(error);
	}
}
