import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import {
	CustomerInvoiceFormView,
	CustomerInvoicePaymentView,
} from 'app/models/miscellaneous-billing/CustomerInvoiceFormView';
import { RemainingOverpaymentView } from 'app/models/RemainingOverpaymentView';

@Injectable({
	providedIn: 'root',
})
export class CustomerInvoiceAdjustmentFormService {
	headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' });
	private readonly urlPath = `${environment.apiUrl}miscellaneous-billing/clients/`;

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form`, request).pipe();
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${id}`, request)
			.pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${id}`).pipe();
	}

	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${id}/decline`, rejectionReason)
			.pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			this.urlPath
		}${clientId}/customer-invoice-adjustment-form/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${id}/review`, {})
			.pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${id}/docs`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${id}`).pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${formId}/docs/${docId}`
		);
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<CustomerInvoiceFormView> {
		return this.httpClient
			.post<CustomerInvoiceFormView>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	search(clientId: string): Observable<CustomerInvoiceFormView[]> {
		return this.httpClient
			.get<CustomerInvoiceFormView[]>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/search`)
			.pipe();
	}

	getPaymentHistory(clientId: string, id: any): Observable<CustomerInvoicePaymentView[]> {
		return this.httpClient
			.get<CustomerInvoicePaymentView[]>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/item/${id}`)
			.pipe();
	}

	itemPayment(clientId: string, request: CustomerInvoicePaymentView): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/item-payment`, request)
			.pipe();
	}

	emailInvoice(clientId: string, id: string): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${id}/email-invoice`, {})
			.pipe();
	}

	export(clientId: string) {
		return this.httpClient
			.get(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/export`, {
				responseType: 'blob',
			})
			.pipe();
	}

	getLastInvoice(clientId: string, customerId: string): Observable<CustomerInvoiceFormView> {
		return this.httpClient
			.get<CustomerInvoiceFormView>(
				`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${customerId}/last-invoice`
			)
			.pipe();
	}

	addOverpaymentItem(clientId: string, formId: string, request: CustomerInvoicePaymentView): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${formId}/overpayment`, request)
			.pipe();
	}

	overpaymentbalance(clientId: string, customerId: string): Observable<RemainingOverpaymentView[]> {
		return this.httpClient
			.get<RemainingOverpaymentView[]>(
				`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${customerId}/overpaymentbalance`
			)
			.pipe();
	}

	overpaymentbalanceforrefund(clientId: string, customerId: string): Observable<RemainingOverpaymentView[]> {
		return this.httpClient
			.get<RemainingOverpaymentView[]>(
				`${this.urlPath}${clientId}/customer-invoice-adjustment-form/${customerId}/overpaymentbalanceforrefund`
			)
			.pipe();
	}

	getNewAdjustmentObject(clientId: string, formTypeId: number, customerInvoiceId: string): Observable<any> {
		return this.httpClient
			.get<any>(
				`${this.urlPath}${clientId}/customer-invoice-adjustment-form/newadjustmentobject/${formTypeId}/${customerInvoiceId}`
			)
			.pipe();
	}
}
