import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { UserService } from '../services/user.service';
import { RefreshTokenSubjectService } from 'app/services/refresh-token-subject.service';
import { UtilityService } from 'app/services/utility.service';
import { datadogRum } from '@datadog/browser-rum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private notificationServices: NotificationService, 
				private userService: UserService, 
				private refreshTokenSubjectService: RefreshTokenSubjectService,
				private utilityService: UtilityService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				
				if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === "application/json") {
					// https://github.com/angular/angular/issues/19888
					// When request of type Blob, the error is also in Blob instead of object of the json data
					return new Promise<any>((resolve, reject) => {
						let reader = new FileReader();
						reader.onload = (e: Event) => {
							try {
								const errmsg = JSON.parse((<any>e.target).result);
								reject(new HttpErrorResponse({
									error: errmsg,
									headers: err.headers,
									status: err.status,
									statusText: err.statusText,
									url: err.url || undefined
								}));
							} catch (e) {
								reject(err);
							}
						};
						reader.onerror = (e) => {
							reject(err);
						};
						reader.readAsText(err.error);
					});
				}
			else {
					if (
						this.isRefreshing &&
						request.url.indexOf('api/authentication/refresh-access-token') !== -1 &&
						err.status === 401
					) {
						this.logOut();
					} else if (err.status === 401 && err.url.indexOf('api/authentication/login') === -1) {
						return this.handle401Error(request, next);
					} else if (err.status === 403) {
						this.notificationServices.showError('You are not authorized to do this action.', 'Not Authorized.');
						return throwError('You are not authorized to do this action.');
					}

					let error = this.utilityService.getErrorMessage(err);
					datadogRum.addError(error);
					return throwError(error);
				}
			})
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);
			const userId = this.userService.currentUserValue?.user?.id;
			const token = this.userService.currentUserValue.refreshToken;
			if (userId) {
				return this.userService.refreshAccessToken(userId, token).pipe(
					switchMap((userLogin: any) => {
						this.isRefreshing = false;
						this.userService.completeLogIn(userLogin, true);
						this.refreshTokenSubject.next(userLogin.token);
						this.refreshTokenSubjectService.triggerLoginSuccess();
						return next.handle(this.addTokenHeader(request, userLogin.token));
					}),
					catchError((err) => {
						this.logOut();
						return throwError(err);
					})
				);
			}
		}

		return this.refreshTokenSubject.pipe(
			filter((token) => token !== null),
			take(1),
			switchMap((token) => next.handle(this.addTokenHeader(request, token)))
		);
	}

	private addTokenHeader(request: HttpRequest<any>, token: string) {
		return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
	}

	private logOut() {
		this.isRefreshing = false;

		const userId = this.userService.currentUserValue?.user?.id;
		const token = this.userService.currentUserValue.refreshToken;

		this.userService.logoutApi(userId, token).subscribe(() => {
			let user = localStorage.getItem(UserService.cookieName);
			if (user) {
				localStorage.removeItem(UserService.cookieName);
			} else {
				user = sessionStorage.getItem(UserService.cookieName);
				if (user) {
					sessionStorage.removeItem(UserService.cookieName);
				}
			}
			location.reload();
		});
	}
}
