import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { MonthlyTimeSheetView } from '../../models/form/MonthlyTimeSheetView';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { UtilityService } from '../utility.service';
import { environment } from '../../../environments/environment';
import { Client } from 'app/models/Client';

@Injectable({
	providedIn: 'root',
})
export class MonthlyTimeSheetService {
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: any, model: any): Observable<MonthlyTimeSheetView> {
		return this.httpClient
			.post<MonthlyTimeSheetView>(
				`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet`,
				this.setupRequest(form, model)
			)
			.pipe();
	}
	addNewTask(clientId: string, form: any): Observable<any> {
		return this.httpClient
			.post<any>(
				`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/add-task`,
				this.setupRequest(form, form)
			)
			.pipe();
	}
	private setupRequest(request: any, model: any) {
		const formData: FormData = new FormData();
		if(model!=null)
		formData.append('attachment', model.attachment);
		formData.append('model', JSON.stringify(request));

		return formData;
	}

	getForm(id: string, clientId: string): Observable<MonthlyTimeSheetView> {
		return this.httpClient
			.get<MonthlyTimeSheetView>(`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/${id}`)
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<MonthlyTimeSheetView> {
		return this.httpClient
			.post<MonthlyTimeSheetView>(
				`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<MonthlyTimeSheetView> {
		return this.httpClient
			.post<MonthlyTimeSheetView>(
				`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/${formId}/approve`,
				{
					userId,
				}
			)
			.pipe();
	}
	sendBack(clientId: string, formId: string): Observable<MonthlyTimeSheetView> {
		return this.httpClient
			.post<MonthlyTimeSheetView>(
				`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/${formId}/review`,
				{}
			)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${
			environment.aclarianId
		}/monthly-timesheet/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	editForm(clientId: string, id: any, request: any) {
		return this.httpClient
			.put<MonthlyTimeSheetView>(
				`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/${id}`,
				request
			)
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<MonthlyTimeSheetView> {
		return this.httpClient
			.post<MonthlyTimeSheetView>(
				`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/${id}/approval`,
				{
					userId,
				}
			)
			.pipe();
	}

	checkTimeSheet(
		clientId: string,
		userId: string,
		currentYear: number,
		month: number
	): Observable<MonthlyTimeSheetView> {
		return this.httpClient
			.get<MonthlyTimeSheetView>(
				`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet?userId=${userId}&year=${currentYear}&month=${month}`
			)
			.pipe();
	}

	uploadFile(clientId: string, formId, file: File, type): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/${formId}/docs?type=${type}`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}
	deleteTask(clientId: string, taskId: number, entryId: number): Observable<any> {
		return this.httpClient
			.get<any>(
				`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/delete-task/${taskId}/${entryId}`
			)
			.pipe();
	}

	getClientList(clientId: string): Observable<Client[]> {
		return this.httpClient
			.get<Client[]>(`${environment.apiUrl}forms/clients/${environment.aclarianId}/monthly-timesheet/clients`)
			.pipe();
	}

	exportData(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/monthly-timesheet/export/${formId}`, {
				responseType: 'blob',
			})
			.pipe();
	}
}
