import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	OnLoading = new EventEmitter<boolean>();
	public isLoading: boolean;

	constructor() {
		this.OnLoading.subscribe((value) => {
			this.isLoading = value;
		});
	}
}
