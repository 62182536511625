<div class="main-content">
	<div class="row">
		<div class="col-md-12">
			<div class="card card-timeline card-plain">
				<div class="card-header" *ngIf="data?.length > 0">
					<h4 class="card-title">
						Announcements
						<div>
							<button (click)="markAllAsRead()" style="font-size: 0.5em" class="btn btn-info btn-round pull-right">
								<i class="fa fa-file-o"> </i> Mark All As Read
							</button>
							<button (click)="markAllAsArchive()" style="font-size: 0.5em" class="btn btn-danger btn-round pull-right">
								<i class="fa fa-file-o"> </i> Delete All
							</button>
						</div>
					</h4>
				</div>
				<div class="card-body">
					<div *ngIf="data?.length == 0" style="height: 500px; padding-top: 200px">
						<span style="margin-left: 40%">No Announcements</span>
					</div>
					<ul class="timeline timeline-simple" *ngIf="data?.length > 0">
						<li class="timeline-inverted" *ngFor="let announcement of data; let i = index">
							<div
								class="timeline-badge"
								[ngClass]="{
									success: announcement?.announcementFors[0].isRead === true,
									info: announcement?.announcementFors[0].isRead === false
								}"
							>
								<i class="nc-icon nc-single-copy-04"></i>
							</div>
							<div class="timeline-panel">
								<div class="timeline-heading">
									<span
										class="badge badge-pill"
										[ngClass]="{
											'badge-success': announcement?.announcementFors[0].isRead === true,
											'badge-info': announcement?.announcementFors[0].isRead === false
										}"
									>
										{{ announcement.title }}</span
									>

									<div class="pull-right" *ngIf="announcement?.announcementFors[0].isRead">
										<span class="badge badge-pill badge-success">Read</span>
									</div>
								</div>
								<div class="timeline-body">
									<div [innerHTML]="announcement.message"></div>
								</div>
								<hr />
								<div class="timeline-footer">
									<div class="pull-left">
										<h6>
											<i style="color: #66615b" class="fa fa-clock"></i>
											{{ utilityService.convertToDate(announcement.scheduledDate) }}
										</h6>
										<h6>
											<i style="color: #66615b" class="fa fa-user"></i> {{ getUserEmail(announcement.createdBy) }}
										</h6>
									</div>
									<div class="dropdown pull-right">
										<button
											type="button"
											class="btn btn-round dropdown-toggle"
											[ngClass]="{
												'btn-success': announcement?.announcementFors[0].isRead === true,
												'btn-info': announcement?.announcementFors[0].isRead === false
											}"
											data-toggle="dropdown"
										>
											<i class="nc-icon nc-settings-gear-65" style="margin-right: 5px"></i>
										</button>
										<div class="dropdown-menu">
											<a
												*ngIf="!announcement?.announcementFors[0].isRead"
												class="dropdown-item"
												href="javascript:void(0)"
												(click)="markAsRead(announcement?.announcementFors[0].id, i)"
												>Mark as read</a
											>
											<a
												class="dropdown-item"
												href="javascript:void(0)"
												(click)="markAsArchive(announcement?.announcementFors[0].id, i)"
												>Delete</a
											>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
