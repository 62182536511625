import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from '../BaseFormService';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TaxCertificateRedemptionCheckService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(
			`${environment.apiUrl}forms/clients/$$clientId$$/tax-certificate-redemption-check`,
			httpClient,
			utilityService
		);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	export(clientId: string, id: string): Observable<any> {
		return this.httpClient
			.get(`${this.getUrl(clientId)}/${id}/export`, {
				responseType: 'blob',
			})
			.pipe();
	}
}
