import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { CapitalBudgetView } from 'app/models/form/CapitalBudgetView';
import { map } from 'rxjs/operators';
import { UtilityService } from 'app/services/utility.service';
import { CapitalBudgetOverview } from 'app/models/CapitalBudgetOverview';
import { BaseFormService } from 'app/services/BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class CapitalBudgetServices extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/capital-budget`, httpClient, utilityService);
	}

	getBudgetForDepartment(clientId: string, departmentId: any, fiscalYear: number, copyPriorYearBudget: boolean): Observable<CapitalBudgetView> {
		return this.httpClient
			.get<CapitalBudgetView>(`${this.getUrl(clientId)}/departments/${departmentId}?fiscalyear=${fiscalYear}&copyPriorYearBudget=${copyPriorYearBudget}`)
			.pipe(
				map((data) => {
					if (data.id == '00000000-0000-0000-0000-000000000000') {
						data.id = '00000000-0000-0000-0000-000000000000';
					}
					return data;
				})
			);
	}

	getAvailableBudgets(clientId: string, fiscalyear: number): Observable<CapitalBudgetView[]> {
		return this.httpClient.get<CapitalBudgetView[]>(`${this.getUrl(clientId)}/available?fiscalyear=${fiscalyear}`);
	}

	getBudgetOverviewByYear(clientId: string, departmentId, year): Observable<CapitalBudgetOverview[]> {
		return this.httpClient
			.get<CapitalBudgetOverview[]>(`${this.getUrl(clientId)}/departments/${departmentId}/overview/${year}`)
			.pipe();
	}

	getBudgetOverview(clientId: string, departmentId): Observable<CapitalBudgetOverview[]> {
		return this.httpClient
			.get<CapitalBudgetOverview[]>(`${this.getUrl(clientId)}/departments/${departmentId}/overview`)
			.pipe();
	}

	getBudgetOverviewForFund(clientId: string, fundId, year?): Observable<CapitalBudgetOverview[]> {
		return this.httpClient
			.get<CapitalBudgetOverview[]>(`${this.getUrl(clientId)}/funds/${fundId}/overview?year=${year}`)
			.pipe();
	}

	getBudgetDetailsByYear(clientId: string, year): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/budgetdetail/${year}`).pipe();
	}

	getCapitalBudgetDetailByYear(clientId: string, year): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/capitalBudgetDetailByYear/${year}`).pipe();
	}
	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.getUrl(clientId)}/${id}/review`, {}).pipe();
	}
	getAllBudgetDetailsByYear(clientId: string, year): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/budgetdetailv2/${year}`).pipe();
	}
	revertCapitalBudgetForm(clientId: string, id: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.getUrl(clientId)}/${id}/RevertCapitalBudgetForm`, {}).pipe();
	}
}
