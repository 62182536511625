import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import { UserWorkflowFormView } from 'app/models/form/UserWorkflowFormView';
import { LoadingService } from '../loading.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
	providedIn: 'root',
})
export class UserWorkflowFormService {

	headers = new HttpHeaders({ 'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0' });

	private readonly urlPath = `${environment.apiUrl}user-management/clients/`;

	constructor( private httpClient: HttpClient, private utilityService: UtilityService,private loadingService: LoadingService) {}

	createForm(clientId: string, request: any): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/forms/user-form`, this.setupRequest(request)).pipe();
	}

	private setupRequest(request: any) {
		const formData: FormData = new FormData();

		formData.append('model', JSON.stringify(request));
		return formData;
	}

	updateForm(clientId: string, id: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${this.urlPath}${clientId}/forms/user-form/${id}`, this.setupRequest(request))
			.pipe();
	}
	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(`${this.urlPath}${clientId}/forms/user-form/${formId}/docs/${docId}`);
	}
	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${this.urlPath}${clientId}/forms/user-form/${id}`).pipe();
	}

	getAllForms(clientId: string, request: any): Observable<any[]> {
		return this.httpClient.post<any[]>(`${this.urlPath}${clientId}/forms/user-form/all`, request).pipe();
	}
	rejectForm(clientId: string, id: string, rejectionReason: { reason: string; workflowId: number }): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/forms/user-form/${id}/decline`, rejectionReason)
			.pipe();
	}

	approveForm(clientId: string, id: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.urlPath}${clientId}/forms/user-form/${id}/approve`, {
				userId,
			})
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${this.urlPath}${clientId}/forms/user-form/users/${userId}?${this.utilityService.objectToQueryString(
			search
		)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	sendBack(clientId: string, id: string): Observable<any> {
		return this.httpClient.post<any>(`${this.urlPath}${clientId}/forms/user-form/${id}/review`, {}).pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest('POST', `${this.urlPath}${clientId}/forms/user-form/${id}/docs`, formData, {
			reportProgress: true,
			responseType: 'json',
		});
		return this.httpClient.request(req);
	}

	deleteForm(clientId: string, id: string) {
		return this.httpClient.delete(`${this.urlPath}${clientId}/forms/user-form/${id}`).pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<UserWorkflowFormView> {
		return this.httpClient
			.post<UserWorkflowFormView>(`${this.urlPath}${clientId}/forms/user-form/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	printPDF(htmlContentElement:any): Observable<boolean> {
		return new Observable<boolean>((observer) => {
			this.loadingService.OnLoading.emit(true);
			setTimeout(() => {
				const contentElement = htmlContentElement;
				html2canvas(contentElement, {
					useCORS: true,
					allowTaint: false,
					scale: 1.5,
				}).then((canvas) => {
					let imgWidth = 500;
					let pageHeight = 700;
					let imgHeight =
						((canvas.height * imgWidth) / 2454) * 1.25;
					var heightLeft = imgHeight;
					const imgData = canvas.toDataURL("image/png");
					const pdf = new jsPDF({
						orientation: "p",
						unit: "mm",
						format: [500, 700],
					});
					pdf.setFillColor('#ffffff')
					let position = 0;
					pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
					heightLeft -= pageHeight;

					while (heightLeft >= 0) {
						position = heightLeft - imgHeight;
						pdf.addPage();
						pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
						heightLeft -= pageHeight;
					}
					pdf.autoPrint();
					const blob = pdf.output('blob');
					const url = URL.createObjectURL(blob);
					const printWindow = window.open(url, '_blank');
					if (printWindow) {
						printWindow.onload = () => {
							printWindow.print();
							printWindow.onafterprint = () => {
								URL.revokeObjectURL(url);
							};
						};
					}
					this.loadingService.OnLoading.emit(false);
					observer.next(true);
					observer.complete();
				});
			}, 300)
		});
	}
}
