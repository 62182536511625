import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { ProjectManagementView } from 'app/models/form/ProjectManagementView';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { UtilityService } from 'app/services/utility.service';
import { environment } from 'environments/environment';
import { ProjectCostView } from 'app/models/form/ProjectCostView';
import { AdditionFormView } from 'app/models/capital-asset/AdditionFormView';

@Injectable({
	providedIn: 'root',
})
export class ProjectOpenFormService {
	private readonly urlPath = 'project-open-form';

	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: any): Observable<any> {
		return this.httpClient.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}`, form).pipe();
	}

	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}`, request)
			.pipe();
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${environment.apiUrl}forms/clients/${clientId}/${
			this.urlPath
		}/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	getForm(clientId: string, id: string): Observable<any> {
		return this.httpClient.get<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}`).pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/decline`, rejectionReason)
			.pipe();
	}

	deleteDocument(clientId: string, formId: any, docId: any): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/docs/${docId}`
		);
	}
	approveForm(clientId: string, formId: string, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/approve`, { userId })
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${formId}/review`, {})
			.pipe();
	}

	uploadFile(clientId: string, id, file: File): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/docs`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/${this.urlPath}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
