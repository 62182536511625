import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AnnouncementsRoutingModule } from './announcements-routing.module';
import { AnnouncementsViewComponent } from './announcements-view/announcements-view.component';
import { AnnouncementsListComponent } from './announcements-list/announcements-list.component';
import { AnnouncementsCreateComponent } from './announcements-create/announcements-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { BtnCellRendererComponent } from './common/btn-cell-renderer/btn-cell-renderer.component';
import { WorkFlowPluginsModule } from 'app/shared/work-flow-plugins/work-flow-plugins.module';

@NgModule({
	declarations: [
		AnnouncementsViewComponent,
		AnnouncementsListComponent,
		AnnouncementsCreateComponent,
		BtnCellRendererComponent,
	],
	imports: [
		CommonModule,
		CKEditorModule,
		AnnouncementsRoutingModule,
		ReactiveFormsModule,
		AgGridModule.withComponents([BtnCellRendererComponent]),
		FormsModule,
		WorkFlowPluginsModule,
	],
})
export class AnnouncementsModule {}
