import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility.service';
import { YeRequestCreator } from 'app/models/form/YeRequestCreator';
import { FormSearch } from 'app/models/dashboard/FormStatus';
import { YeRequestFormView } from 'app/models/form/YeRequestFormView';
import { BaseDashboard } from 'app/models/dashboard/BaseDashboard';
import { YeRequestCommentView } from 'app/models/form/YeRequestCommentView';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class YeRequestFormService {
	constructor(private httpClient: HttpClient, private utilityService: UtilityService) {}

	createForm(clientId: string, form: YeRequestCreator): Observable<YeRequestFormView> {
		return this.httpClient
			.post<YeRequestFormView>(`${environment.apiUrl}forms/clients/${clientId}/ye-request`, form)
			.pipe();
	}

	updateForm(clientId: string, formId: string, request: any): Observable<any> {
		return this.httpClient
			.put<any>(`${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}`, request)
			.pipe();
	}

	submitDeliverables(clientId: string, formId: string, request: any): Observable<YeRequestFormView> {
		return this.httpClient
			.post<YeRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}/deliverables`,
				request
			)
			.pipe();
	}

	uploadFile(clientId: string, formId, file: File, type): Observable<HttpEvent<any>> {
		const formData: FormData = new FormData();
		formData.append('file', file);
		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}/docs?type=${type}`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);
		return this.httpClient.request(req);
	}

	getFormsFor(clientId: string, userId: string, search: FormSearch): Observable<BaseDashboard<any>> {
		let url = `${
			environment.apiUrl
		}forms/clients/${clientId}/ye-request/users/${userId}?${this.utilityService.objectToQueryString(search)}`;
		return this.httpClient.get<BaseDashboard<any>>(url).pipe();
	}

	getForm(id: string, clientId: string): Observable<YeRequestFormView> {
		return this.httpClient
			.get<YeRequestFormView>(`${environment.apiUrl}forms/clients/${clientId}/ye-request/${id}`)
			.pipe();
	}

	rejectForm(
		clientId: string,
		formId: string,
		rejectionReason: { reason: string; workflowId: number }
	): Observable<YeRequestFormView> {
		return this.httpClient
			.post<YeRequestFormView>(
				`${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}/decline`,
				rejectionReason
			)
			.pipe();
	}

	approveForm(clientId: string, formId: string, userId: string): Observable<YeRequestFormView> {
		return this.httpClient
			.post<YeRequestFormView>(`${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}/approve`, {
				userId,
			})
			.pipe();
	}

	removeDeliverable(clientId: string, formId: string, documentId: string): Observable<any> {
		return this.httpClient
			.delete<any>(`${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}/deliverables/${documentId}`)
			.pipe();
	}

	sendComment(clientId: string, formId: string, item: YeRequestCommentView): Observable<YeRequestCommentView> {
		return this.httpClient
			.post<YeRequestCommentView>(`${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}/comments`, item)
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<YeRequestFormView> {
		return this.httpClient
			.post<YeRequestFormView>(`${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}/review`, {})
			.pipe();
	}

	exportToExcel(clientId: string, userId: string, search: any) {
		return this.httpClient
			.get(
				`${
					environment.apiUrl
				}forms/clients/${clientId}/ye-request/users/${userId}/export?${this.utilityService.objectToQueryString(
					search
				)}`,
				{
					responseType: 'blob',
				}
			)
			.pipe();
	}

	printForm(clientId: string, formId: string) {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/ye-request/${formId}/print`, {
				responseType: 'blob',
			})
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/ye-request/${id}/approval`, {
				userId,
			})
			.pipe();
	}
}
