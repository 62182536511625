import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from 'app/services/loading.service';
import { NotificationService } from 'app/services/notification.service';
import { UserService } from 'app/services/user.service';
import { UtilityService } from 'app/services/utility.service';
import { FormCommentView } from 'app/models/form/FormCommentView';
import { FormCommentService } from 'app/services/form/form-comment.service';
import { WorkflowForm } from 'app/models/WorkflowForm';
import { FormOptions } from 'app/models/dashboard/FormStatus';

@Component({
	selector: 'app-fieldwork-comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.css'],
})
export class CommentsComponent implements OnInit {
	@Input() comments: FormCommentView[] = [];
	@Input() form: any;
	@Input() currentApprover: string;
	@Input() showFile: boolean = true;
	@Input() serviceType: string;
	@Input() serviceTypeEnum: FormOptions;
	newCommentText: string = '';
	@Input() canApprove: boolean;
	userId: string;
	private attachment: File;

	constructor(
		private formCommentService: FormCommentService,
		private loadingService: LoadingService,
		private userService: UserService,
		private utilityService: UtilityService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.userId = this.userService.currentUserValue.user.id;
	}

	public allowComment() {
		if (this.form) {
			let allowEdit = this.form?.workflowStep == 'Completed';
			if (allowEdit) {
				return true;
			}

			if (
				(this.form.workflowStep == 'Fulfillment' || this.form.workflowStep == 'Workflow Started') &&
				this.userId == this.currentApprover
			) {
				return true;
			}

			if (
				this.form.workflowStep == 'Workflow Started' &&
				(this.serviceType == 'expense-budget' ||
					this.serviceType == 'revenue-budget' ||
					this.serviceType == 'capital-budget' ||
					this.serviceType == 'leave-balance-adjustment' ||
					this.serviceType == 'timesheet-approval-form')
			) {
				return true;
			}
			if (this.form.workflowStep != 'Completed') {
				return true;
			}

			return (
				!this.utilityService.hasApproved(this.form, this.userId) &&
				this.utilityService.isApprover(this.form, this.userId)
			);
		}
		return false;
	}

	allowEdit(item: FormCommentView) {
		if (this.form) {
			return this.userId == item.createdById || this.userId == item.createdBy;
		}
		return false;
	}

	addComment(comment: FormCommentView) {
		let newComment: FormCommentView = {
			comment: '',
			id: 0,
			parentId: comment.id,
			isEditing: true,
		};
		comment.comments.push(newComment);
	}

	removeComment(comment: FormCommentView) {
		comment.comments.pop();
	}

	editComment(comment: FormCommentView) {
		comment.isEditing = true;
	}

	cancelEdit(comment: FormCommentView) {
		comment.isEditing = false;
	}

	submitComment(item: FormCommentView) {
		item.isEditing = false;
		if (item.comments && item.comments.length > 0) {
			for (let com of item.comments) {
				com.isEditing = false;
				com.createdBy= this.userService.currentUserValue.user.id;
			}
		}

		this.loadingService.OnLoading.emit(true);
		this.formCommentService
			.sendComment(this.form.clientId, this.form.id, item, this.attachment, this.serviceType, this.serviceTypeEnum)
			.subscribe(
				(data) => {
					this.loadingService.OnLoading.emit(false);
					data.isEditing = false;
					let previousComment = this.comments.find((t) => t.id == item.id);
					if (previousComment) {
						previousComment.comment = data.comment;
						previousComment.isEditing = data.isEditing;
						previousComment.modifiedDate = data.modifiedDate;
						if (previousComment.comments && data.comments) {
							previousComment.comments = [];
							for (let sub of data.comments) {
								previousComment.comments.push(sub);
							}
						}
					}
				},
				() => {
					this.notificationService.showError('There was an error', 'Error');
					this.loadingService.OnLoading.emit(false);
				}
			);
	}

	newComment() {
		let newComment: FormCommentView = {
			comment: this.newCommentText,
			id: 0,
			isReply: false,
			createdBy: this.userService.currentUserValue.user.id,
		};

		this.loadingService.OnLoading.emit(true);
		this.formCommentService
			.sendComment(
				this.form.clientId,
				this.form.id,
				newComment,
				this.attachment,
				this.serviceType,
				this.serviceTypeEnum
			)
			.subscribe(
				(data) => {
					this.loadingService.OnLoading.emit(false);
					this.comments.push(data);
					this.newCommentText = '';
					this.attachment = null;
					this.notificationService.showSuccess('Comment Saved', 'Success');
				},
				() => {
					this.notificationService.showError('There was an error', 'Error');
					this.loadingService.OnLoading.emit(false);
				}
			);
	}

	onFileChange($event: Event) {
		if ($event) {
			this.attachment = ($event.target as HTMLInputElement).files[0];
		}
	}
}
