import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from 'app/services/BaseFormService';
import { environment } from 'environments/environment';
import { TaxDistributionView } from 'app/models/form/tax-distribution/TaxDistributionView';

@Injectable({
	providedIn: 'root',
})
export class TaxDistributionService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/tax-dist`, httpClient, utilityService);
	}

	createForm(clientId: string, form: any): Observable<TaxDistributionView> {
		const formData: FormData = new FormData();
		for (let i = 0; i < form.attachments.length; i++) {
			const name = `attachment-${i + 1}`;
			formData.append(name, form.attachments[i].file);
			form.attachments[i].file = null;
			form.attachments[i].name = name;
		}

		formData.append('model', JSON.stringify(form));
		return this.httpClient
			.post<TaxDistributionView>(`${environment.apiUrl}forms/clients/${clientId}/tax-dist`, formData)
			.pipe();
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<TaxDistributionView> {
		return this.httpClient
			.post<TaxDistributionView>(`${environment.apiUrl}forms/clients/${clientId}/tax-dist/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	sendBack(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${environment.apiUrl}forms/clients/${clientId}/tax-dist/${formId}/review`, {})
			.pipe();
	}

	exportForm(clientId: string, formId: string): Observable<any> {
		return this.httpClient
			.get(`${environment.apiUrl}forms/clients/${clientId}/tax-dist/${formId}/export-tax-distribution-form`, {
				responseType: 'blob',
			})
			.pipe();
	}
}
