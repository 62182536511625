import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BaseFormService } from '../BaseFormService';
import { UtilityService } from '../utility.service';
import { CashieringEntryViewModel } from 'app/models/business-license/CashieringEntryViewModel';

@Injectable({
	providedIn: 'root',
})
export class PaymentPlanFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}utility-billing/clients/$$clientId$$/payment-plan-form`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}

	getAllByAccountId(clientId: string, accountId: any): Observable<any[]> {
		return this.httpClient
			.get<any[]>(`${this.getUrl(clientId)}/getall/${accountId}`)
			.pipe();
	}

	exportPdf(clientId: string, id: string): Observable<any> {
		let url = `${this.getUrl(clientId)}/pdf/${id}`;
		return this.httpClient.get<any>(url).pipe();
	}

	markPaymentPlanPaid(clientId: string, request: CashieringEntryViewModel, id: string): Observable<any> {
		return this.httpClient.post(`${this.getUrl(clientId)}/${id}/payment`, request).pipe();
	}
}
