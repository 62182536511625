import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseDashboard } from '../../models/dashboard/BaseDashboard';
import { FormSearch } from '../../models/dashboard/FormStatus';
import { UtilityService } from '../utility.service';
import { TravelPreApprovalView } from 'app/models/form/TravelPreApprovalView';
import { BaseFormService } from '../BaseFormService';
import { TrainingCertificationView } from 'app/models/form/TrainingCertificationView';

@Injectable({
	providedIn: 'root',
})
export class TrainingCertificationFormService extends BaseFormService {
	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/training-certification-form`, httpClient, utilityService);
	}

	submitForApproval(clientId: string, id: any, userId: string): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/approval`, {
				userId,
			})
			.pipe();
	}
	submitDeliverables(clientId: string, formId: string, request: any): Observable<TrainingCertificationView> {
		const formData: FormData = new FormData();
		for (let i = 0; i < request.deliverables.length; i++) {
			const name = `deliverable-${i + 1}`;
			formData.append(name, request.deliverables[i].file);
			request.deliverables[i].file = null;
			request.deliverables[i].name = name;
		}

		formData.append('model', JSON.stringify(request));

		return this.httpClient
			.post<TrainingCertificationView>(`${this.getUrl(clientId)}/${formId}/deliverables`, formData)
			.pipe();
	}
	exportToPdf(clientId: string, formId: string): any {
		return this.httpClient
			.get(`${this.getUrl(clientId)}/${formId}/export`, {
				responseType: 'blob',
			})
			.pipe();
	}
}
