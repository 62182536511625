import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './security/authGuard';
import { AdminAuthGuard } from './security/adminAuthGuard';

export const AppRoutes: Routes = [
	{
		path: 'admin',
		component: AdminLayoutComponent,
		loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule),
		canActivate: [AdminAuthGuard],
	},
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
	},
	{
		path: '',
		component: AuthLayoutComponent,
		children: [
			{
				path: 'authentication',
				loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
			},
		],
	},
	{
		path: '',
		component: AdminLayoutComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
				canActivate: [AuthGuard],
			},
		],
	},
];
