import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UtilityService } from '../utility.service';
import { BaseFormService } from 'app/services/BaseFormService';

@Injectable({
	providedIn: 'root',
})
export class IndividualPaymentService extends BaseFormService {
	private showDuplicateInvoicePopup = new BehaviorSubject<boolean>(false);

	constructor(httpClient: HttpClient, utilityService: UtilityService) {
		super(`${environment.apiUrl}forms/clients/$$clientId$$/individual-payment`, httpClient, utilityService);
	}

	convertToApinvoice(clientId: string, id: any, poNumber: string, userId): Observable<any> {
		return this.httpClient
			.post<any>(`${this.getUrl(clientId)}/${id}/convert-form/${poNumber}`, {
				userId,
			})
			.pipe();
	}

	getRecurringForms(clientId: string): Observable<any[]> {
		return this.httpClient.get<any[]>(`${this.getUrl(clientId)}/recurring-forms`).pipe();
	}

	showDuplicateInvoicePopupChange$ = this.showDuplicateInvoicePopup.asObservable();
	setShowDuplicateInvoicePopup(value: boolean) {
		this.showDuplicateInvoicePopup.next(value);
	}
}
